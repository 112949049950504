import styled from 'styled-components'
import {Container} from 'react-bootstrap'


export const FullContainer = styled(Container)`
width:100%;
background-image:url(${(props)=>props.bg});
background-position:center;
background-size:cover;
display:flex;
align-items:center;
flex-direction:column;
`

