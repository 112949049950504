import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import axios from 'axios'
export default class FacilityInfo extends Component {
    state = {
        school_id:null,
        facilities:[],
        selectedFacilities:[]
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.data!==this.props.data){
            let arr=[]
            nextProps.data.forEach(element => {
                if(element.in_school===1)
                arr.push(element.id)
            });
            this.setState({
                selectedFacilities:arr,
                facilities:nextProps.data,
                school_id:nextProps.ff,
            })
            console.log(nextProps.data)
        }
    }
    handleChange=(e)=>{

        if(e.target.checked){
            this.setState({
                [e.target.name]:[...this.state.selectedFacilities,parseInt(e.target.value)],

            })
        }else{
            let list = [...this.state.selectedFacilities]
            list.splice(list.indexOf(parseInt(e.target.value)),1)
            this.setState({
                [e.target.name]:list
            })
        }
    }
    handleSubmit=e=>{
        e.preventDefault()
        if(this.state.selectedFacilities && this.state.selectedFacilities.length>0){
            let facility_ids ="";
            this.state.selectedFacilities.forEach((element,index)=>{
                if(index!==this.state.selectedFacilities.length-1)
                facility_ids+=element+","
                else
                facility_ids+=element
            })
            console.log(facility_ids)
            let formdata = new FormData()
            formdata.set("school_id",this.state.school_id)
            formdata.set("facility_ids",facility_ids)
            axios.post("/addEditFacilitys",formdata)
            .then(res=>{
                if(res.status===200 && res.data.status===1){
                    alert("update successfully")
                }
            })
        }
    }
    render() {
console.log(this.state.selectedFacilities)
        return (
            <Form className="p-2" onSubmit={this.handleSubmit}>
            <Form.Group>
                {
                    this.state.facilities.length>0?
                    this.state.facilities.map((facility,index)=>(
                        <div key={index}>
                            {
                                this.flag=false
                            }
                            {
                               this.state.selectedFacilities && this.state.selectedFacilities.length>0? this.state.selectedFacilities.map((selected,index)=>(
                                    selected===facility.id?
                                    this.flag=true:""
                                )):""
                            }
<Form.Check key={index} checked={this.flag} name="selectedFacilities" value={facility.id} onChange={this.handleChange} label={facility.name}/>
                        </div>
                    )):""
                   
                }
           
            </Form.Group>
            <Form.Group>
                <Button type="submit" variant="secondary">Update</Button>
            </Form.Group>
        </Form>
        )
        }
}

