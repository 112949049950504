import React, { Component } from 'react'
import NavbarDefault from '../Components/Navbar/NavbarDefault'
import { Container, Card, Col, Row, Button, Pagination } from 'react-bootstrap'
import axios from 'axios'
import Footer from '../Components/Footer'
import Parser from 'rss-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSync} from '@fortawesome/free-solid-svg-icons'
import MetaTags from 'react-meta-tags'

class newsFeeds extends Component {
    parser = new Parser()
    state = {
        articles:{
            items:[]
        },
        articleItem:[],
        page:1,
        isLoading:false
    }

    componentDidMount(){
        this.setState({
            isLoading:true
        })

        axios.get("/getNews")
            .then(res =>{
                this.setState({
                    articles: {
                        items:res.data.channel.item,
                        articleItem:res.data.channel.item
                    }
                })
                this.nexPage(this.state.page,res.data.channel.item)
            }).catch(err=>{
                console.log(err)
        })    
    }
    nexPage(page,feed){
        feed = feed.slice((page-1)*7, page*7)
        this.setState({
          articleItem:feed,
          isLoading:false
       })

    }
    handleClick=(number,e)=>{
        if(this.state.page!==number){
       this.setState({
           page:number
       })
       this.nexPage(number,this.state.articles.items)
    }
    }
    render() {
        
        let items = [];
        if(this.state.articles.items!==undefined) {
            for (let number = 1; number <= Math.ceil(parseFloat(this.state.articles.items.length/7)); number++) {
                items.push(
                    <Pagination.Item onClick={this.handleClick.bind(this,number)} key={number} active={number === this.state.page}>
                        {number}
                    </Pagination.Item>,
                );
            }
        }

        return (
            <div>
                <MetaTags>
                    <title>News | Feel</title>
                    <meta name="description" content="Login page Feel, Feel Login, Feel Signup, Feel School Search engine, best school near me" />
                    <meta name="keywords" content="Feel Login, Feel educare Login, Feel Signup, schools near me, best schools near me , schools in Rajasthan, Schools in Jaipur" />
                    {/* <meta property="og:image" content="path/to/image.jpg" /> */}
                </MetaTags>
                <NavbarDefault />
                <Container className="mt-4 d-flex" style={{flexDirection:"column"}}>
                    <h2 style={this.state.isLoading?{display:"none"}:{}}>Top News</h2>
                    {
                        this.state.articleItem.length>0? this.state.articleItem.map((article,index)=>(
                            <Card key={index} className="m-3 shadow p-2">
                            <Row>
                                <Col sm={4}>
                                <img alt="article" style={{width:"100%"}} height={200} src={article.description.substring(article.description.lastIndexOf("src=")+5,article.description.indexOf("/>")-2)} />
                                
                
                                </Col>
                                <Col>
                            <Card.Body>
                        <Card.Title>{article.title}</Card.Title>
                        <Card.Text>{article.contentSnippet}</Card.Text>
                            </Card.Body>
                            <a href={"mailto:?subject="+article.title+"&body="+article.contentSnippet+" "+article.link}><Button className="float-right">Share</Button></a>
                            <a href={article.link}><Button className="float-right mr-2" variant="outline-secondary">Read More</Button></a>
                            </Col>
                            </Row>
                        </Card>
                        )):<h4 className="mx-auto">Synchronizing &nbsp;<FontAwesomeIcon  icon={faSync} spin /></h4>
                    }
               <Pagination style={this.state.isLoading?{display:"none"}:{}} className="mx-auto mb-2">{items}</Pagination>

                </Container>
                <Footer bottom={this.state.isLoading}/>
            </div>
        )
    }
}
export default newsFeeds