import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import axios from 'axios'

export default class ClassesInfo extends Component {
    state = {
        classes:[],
        clasData:[],
        class_id:[],
        error:false
    }
    componentDidMount(){
        axios.get("/getCommonData")
        .then(res=>{
            if(res.status===200){
                if(res.data.data.status===1){
                    this.setState({
                        classes:res.data.data.classes
                    })
                }
            }
        })
    }
    handleSubmit=e=> {
        e.preventDefault()
        console.log(this.state.class_id.length)
        if(this.state.class_id.length>0) {
            let list = []
            this.state.class_id.forEach((ele,index)=>{
                list.push({class_id:ele,subject_ids:1,section_ids:1})
            })
            this.state.clasData.push(list)
            this.props.data.screen(3,this.state.clasData)
        }else{
            this.setState({
                error:true
            })
        }
    }
    handleChange=e=> {
        if(e.target.checked) {
        this.setState({
            [e.target.name]:[...this.state.class_id,e.target.value] 
        })
        }else{
            let list = [...this.state.class_id]
            list.splice(list.indexOf(e.target.value),1)
            this.setState({
                class_id:list
            })
        }
    }
    render() {
        console.log(this.state)
        return (
            <div>
                <h1 className="text-center">Add Classes</h1>
                <Form className="p-2" onSubmit={this.handleSubmit}>
                    <Form.Group>
                    {
                        this.state.classes && this.state.classes.length>0?this.state.classes.map((clas,index)=>(
                            <Form.Check name="class_id" onChange={this.handleChange} key={index} value={clas.id} type="checkbox" label={clas.name}/>
                        )):""
                    }
                    </Form.Group>
                    <Form.Group>
                        <span className="text-danger">{this.state.error?"please select class":""}</span>
                    </Form.Group>
                    <Form.Group>
                        <Button type="submit" variant="secondary">Next</Button>
                    </Form.Group>
                </Form>
            </div>
        )
    }
}
