import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import appLogo from '../assets/images/feel_phone.webp'
import playLogo from '../assets/images/play_store.webp'
import { Image,FullContainer } from '../Styled/Section';
import {Maindiv} from '../Styled/Hero'
import ind from '../assets/images/INDIA.webp'

export default class SectionThree extends Component {
    render() {
        return (
        <Maindiv>
<FullContainer fluid className="mx-auto">
    <Row style={{width:"100%"}} className="my-auto text-center">
    <Col sm={12} md={7}>
        <h1 className="p-3"  style={{fontSize:"4rem"}}>Make In</h1>
        <div className="p-3">
<Image  src={ind} width={520} widthSm={320} widthMd={420} alt="make-in-india-img-feel"/>
</div>
<div className="p-3">
<a href="https://play.google.com/store/apps/details?id=com.feeleducare.student"><Image src={playLogo} alt="google-playstore-feel-app" width={200} /></a>
</div>
</Col>
<Col sm={12} md={5}>
    <Image  src={appLogo} height={500} heightSm={365} heightMd={420} alt="feel-app-logo"/>
</Col>

    </Row>

</FullContainer>
        </Maindiv>
        )
    }
}
