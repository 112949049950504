import styled, {css} from 'styled-components'
import { Nav } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export const Text = styled(Nav.Link)`
font-family:'Montserrat',sans-serif;
font-size:14px;
`
export const StyledFooter = styled.footer`
position:relative;
width:100%;
${(props)=>props.bottom && css `
position:absolute;
left:50%;
bottom:0;
transform:translateX(-50%)
`}
`
export const CustomizedFa =styled(FontAwesomeIcon)`
color:#343A40;
&:hover{
color:#000;
}
`