import React, { Component } from 'react'
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
export default class Map extends Component {
    onMarkerDragEnd =(event)=>{
        console.log(event)
         }
         shouldComponentUpdate(nextProps, nextState){
             if(this.props.latitude===nextProps.latitude){
                 return false
             }else{
                 return true
             }
         }
    render() {
        const AsyncMap = withScriptjs(
            withGoogleMap(
             props => (
              <GoogleMap
               defaultZoom={15}
               defaultCenter={{ lat: this.props.latitude, lng: this.props.longitude }}
              >
                                    <Marker
                                    onDragEnd={this.onMarkerDragEnd()}
                            draggable={true}
                            position={{lat:this.props.latitude, lng:this.props.longitude}}
                            />
         </GoogleMap>
         )));
         let map =""
         if(this.props.latitude!==undefined ){
             map = <AsyncMap
             googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB_PBN7fBrfdPUOg_JE1LB7CkQQuAVNwHQ&libraries=places"
             loadingElement={<div style={{ height: `100%` }} />}
             containerElement={<div style={{ height:"300px" }} />}
             mapElement={<div style={{ height: `100%` }} />}
             />
         }else{
            map = <div style={{height: "300px"}} />
         }
        return (map)
    }
}
