import React, {useEffect} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import home from './Pages/home';
import 'bootstrap/dist/css/bootstrap.min.css'
import {GlobalStyle} from './Styled/Common'
import 'animate.css'
import axios from 'axios';
import searchResults from './Pages/searchResults';
import notFound from './Pages/notFound';
import login from './Pages/login';
import schoolProfile from './Pages/schoolProfile';
import schoolLogin from './Pages/schooLogin';
import admission from './Pages/admission';
import admissionDetails from './Pages/admissionDetails';
import admissionStatus from './Pages/admissionStatus';
import registration from './Pages/registration';
import editprofile from './Pages/editprofile';
import ReactGA from 'react-ga';
import newsFeeds from './Pages/newsFeeds';
import upload from './Pages/upload';
axios.defaults.baseURL = "https://feeleducare.com/feel-app/api"
// axios.defaults.baseURL = "http://localhost:8000/api"

function App() {
  useEffect(() => {
    ReactGA.initialize('G-REGDN1WPFQ')
    // ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])
  return (
    <BrowserRouter>
    <GlobalStyle/>
    <Switch>
      <Route exact path="/" component={home} />
      <Route exact path="/search" component={searchResults} />
      <Route exact path="/login" component={login}/>
      <Route exact path="/schoolprofile/:IdAndSchoolName" component={schoolProfile} />
      <Route exact path="/schoolprofile/:id" component={schoolProfile} />
      <Route exact path="/registerschool" component={schoolLogin}/>
      <Route exact path="/admission/:id" component={admission}/>
      <Route exact path="/myprofile" component={admissionDetails}/>
      <Route exact path="/admissionstatus" component={admissionStatus} />
      <Route exact path="/registration" component={registration} />
      <Route exact path="/editprofile" component={editprofile}/>
      <Route exact path="/newsfeed" component={newsFeeds}/>
      <Route exact path="/upload" component={upload} />
      <Route component={notFound} />
    </Switch>
    </BrowserRouter>
  );
}

export default App;
