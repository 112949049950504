import React, { Component } from 'react'
import NavbarDefault from '../Components/Navbar/NavbarDefault'
import Footer from '../Components/Footer'
import axios from 'axios'
import { Container, Tabs, Tab } from 'react-bootstrap'
import AdmissionStatus from '../Components/AdmissionStatus'
import MetaTags from 'react-meta-tags'

export default class admissionStatus extends Component {
    state = {
        isLoading:true,
        school_id:null,
        upcoming:[],
        ongoing:[],
        cancelled:[],
        completed:[]
    }
    componentDidMount(){
        console.log(localStorage.getItem("school_id"));
        this.setState({school_id:localStorage.getItem("school_id")}, this.getlist());
        
    }
    
    getlist(){
        let formdata = new FormData()
        if(localStorage.getItem("school_id") !==null){
        formdata.set("school_id", localStorage.getItem("school_id"))
        axios.post("/getAdmissionReqListing",formdata)
        .then(res=>{
           console.log(res);
           
            if(res.status===200 && res.data.status===1){
                if(res.data.data.upcoming && res.data.data.upcoming.length>0){
                    this.setState({upcoming: res.data.data.upcoming, isLoading:false})
                }
                if(res.data.data.ongoing && res.data.data.ongoing.length>0){
                    this.setState({ongoing: res.data.data.ongoing, isLoading:false})
                }
                if(res.data.data.completed!==null && res.data.data.completed.length>0){
                    this.setState({completed:res.data.data.completed, isLoading:false})
                }
                if(res.data.data.cancelled!==null && res.data.data.cancelled.length>0){
                    this.setState({cancelled:res.data.data.cancelled, isLoading:false})

                }
            }else {
                this.setState({isLoading:false});
            }
        }).catch(err=>{
            console.log(err.response);
            this.setState({isloading:false});
        })
    }else{
        this.setState({isloading:false});
        alert("something went wrong")
    }

    }
    render() {
        return (
            <div>
                <MetaTags>
                    <title>FEEL | School Search Engine</title>
                    <meta name="description" content="Login page Feel, Feel Login, Feel Signup, Feel School Search engine, best school near me" />
                    <meta name="keywords" content="Feel Login, Feel educare Login, Feel Signup, schools near me, best schools near me , schools in Rajasthan, Schools in Jaipur" />
                    {/* <meta property="og:image" content="path/to/image.jpg" /> */}
                </MetaTags>
                <NavbarDefault/>
                <Container className="mt-5">
                    <Tabs>
                        <Tab eventKey="upcoming" title="Upcoming">
                            <AdmissionStatus isLoading = {this.state.isLoading} tab={1} data={this.state.upcoming}/>
                        </Tab>
                        <Tab eventKey="ongoing" title="Ongoing">
                            <AdmissionStatus isLoading = {this.state.isLoading} tab={2} data={this.state.ongoing} />
                        </Tab>
                        <Tab eventKey="completed" title="Completed">
                            <AdmissionStatus isLoading = {this.state.isLoading} tab={3} data={this.state.completed} />
                        </Tab>
                        <Tab eventKey="cancelled" title="Cancelled">
                            <AdmissionStatus isLoading = {this.state.isLoading} tab={4} data={this.state.cancelled}/>
                        </Tab>
                    </Tabs>
                    </Container>
                    <Footer />
            </div>
        )
    }
}
