import React, { Component } from 'react'
import { Container, Form, Button } from 'react-bootstrap'
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import MetaTags from 'react-meta-tags'

class Admission extends Component {
    state = {
        parent_id:"",
        school_id:"",
        student_name:"",
        guardian_name:"",
        dob_no:"",
        gender:"",
        class_id:null,
        board_id:null,
        medium_id:"",
        contact_phone:"",
        contact_email:"",
        aadhar_no:"",
        classes:[],
        selectedClass:"",
        selectedBoard:""
    }
    componentDidMount(){
        this.setState({parent_id:localStorage.getItem("parent_id"),school_id:this.props.id})
        axios.get("/getCommonData")
        .then(res=>{
            if(res.status===200){
                if(res.data.data.status===1){
                    this.setState({classes:res.data.data.classes})
                }
            }
        })
        .catch(err=>{

        })
    }
    handleChange=e=>{
        this.setState({
            [e.target.name] : e.target.value
        })
        if(e.target.name==="selectedClass"){
            this.setState({class_id:e.target.options.selectedIndex})
        }
        if(e.target.name==="selectedBoard"){
            this.setState({board_id:e.target.options.selectedIndex})
        }
    }
    handleSubmit=e=>{
        e.preventDefault()
        console.log(this.state)
        let formdata = new FormData()
        let arr=[{
            parent_id:this.state.parent_id,
            school_id:this.state.school_id,
            student_name:this.state.student_name,
            guardian_name:this.state.guardian_name,
            dob_no:this.state.dob_no,
            gender:this.state.gender,
            class_id:this.state.class_id,
            board_id:this.state.board_id,
            medium_id:this.state.medium_id,
            contact_phone:this.state.contact_phone,
            contact_email:this.state.contact_email,
            aadhar_no:this.state.aadhar_no,
        }
        ]
        formdata.set("studRecord",JSON.stringify(arr));
        if(this.state.student_name==="" || this.state.guardian_name==="" || this.state.contact_email ==="" || this.state.contact_phone==="" || this.state.gender==="" || this.state.medium_id===""){
            alert("please fill the required field")
        }else if(this.state.school_id===""){
            alert("something went wrong")
        }else{
            axios.post("/admissionRequest",formdata)
            .then(res=>{
                console.log(res)
                if(res.status===200){
              if(res.data.data.status===1){
                  this.props.history.push({pathname:"/admissiondetails",state:{
                      data:res.data.data.adData
    
                  }})
              }      
                }else{
                    alert("network error")
                }
            })
        }

    }
    render() {
        return (
            <Container className="col-md-6 col-lg-4 shadow my-4 p-4" placeholder="Student Name">
                <MetaTags>
                    <title>Login • Feel</title>
                    <meta name="description" content="Login page Feel, Feel Login, Feel Signup, Feel School Search engine, best school near me" />
                    <meta name="keywords" content="Feel Login, Feel educare Login, Feel Signup, schools near me, best schools near me , schools in Rajasthan, Schools in Jaipur" />
                    {/* <meta property="og:image" content="path/to/image.jpg" /> */}
                </MetaTags>
                <h1 className="text-center">Admission Form</h1>
               <Form onSubmit={this.handleSubmit}>
                   <Form.Group>
                   <Form.Control value={this.state.student_name} name="student_name" type="text" placeholder="Student Name" onChange={this.handleChange} required/>
                   </Form.Group>
                   <Form.Group>
                   <Form.Control value={this.state.guardian_name} name="guardian_name" type="text" placeholder="Guardian Name" onChange={this.handleChange} required/>
                   </Form.Group>
                   {/*<Form.Group>
                       <Form.Control type="date" required/>
                   </Form.Group>*/}
                   <Form.Group>
                   <Form.Control value={this.state.dob_no} name="dob_no" type="text" placeholder="Birth Certificate Number" onChange={this.handleChange} />
                   </Form.Group>
                   <Form.Group>
                   <Form.Control value={this.state.aadhar_no} name="aadhar_no" type="tel" maxLength="12" placeholder="Aadhar Number" onChange={this.handleChange} required/>
                   </Form.Group>
                   <Form.Group>
                       <Form.Label>Gender</Form.Label>
                       <div>
                       <Form.Check onChange={this.handleChange} value="1" name="gender" label="Male" inline type="radio" />
                       <Form.Check onChange={this.handleChange} value="2" name="gender" label="Female" inline type="radio" />
                       </div>
                   </Form.Group>
                   <Form.Group>
                       <Form.Label>Medium</Form.Label>
                       <div>
                   <Form.Check onChange={this.handleChange} name="medium_id" value="1" label="Hindi" inline type="radio" />
                       <Form.Check onChange={this.handleChange} name="medium_id" value="2" label="English" inline type="radio" />
                       </div>
                   </Form.Group>
                   <Form.Group>
                       <Form.Control value={this.state.selectedClass} name="selectedClass" as="select" onChange={this.handleChange}>
                           <option>Select Class</option>
                          {
                              this.state.classes?this.state.classes.map((clas,index)=>(
                              <option key={clas.id}>{clas.name}</option>
                              )):""
                          }
                       </Form.Control>
                       
                   </Form.Group>
                   <Form.Group>
                       <Form.Control onChange={this.handleChange} name="selectedBoard" value={this.state.selectedBoard} as="select">
                           <option>Select Board</option>
                           <option key={1}>CBSE</option>
                           <option key={2}>RBSE</option>
                       </Form.Control>
                   </Form.Group>
<Form.Group>
    <Form.Control onChange={this.handleChange} value={this.state.contact_phone} name="contact_phone" type="tel" maxLength="10" placeholder="Mobile Number"  required/>
</Form.Group>
<Form.Group>
    <Form.Control onChange={this.handleChange} value={this.state.contact_email} name="contact_email" type="email" placeholder="Email" required />
</Form.Group>
<Button type="submit">Apply</Button>
               </Form>
            </Container>
        )
    }
}
export default withRouter(Admission)