import React, {Component} from "react"
import { Card,InputGroup,FormControl, Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import date from '../assets/images/svg/date.svg'
import email from '../assets/images/svg/email.svg'
import name from '../assets/images/svg/name.svg'
import location from '../assets/images/svg/location.svg'
import axios from 'axios'
import { withRouter } from 'react-router-dom'

class ParentInput extends Component {
    state = {
        name:"",
        dob:"",
        email:"",
        city:"",
        gender:"",
        error:false,
        loading:false
    }
    handleChange = e =>{
        this.setState({[e.target.name] : e.target.value})
    }
    handleClick =e=>{
        let bodydata = new FormData();
        bodydata.set("parent_id",this.props.parent_id);
        bodydata.set("user_type","P");
        bodydata.set("full_name", this.state.name);
        bodydata.set("dob", this.state.dob);
        bodydata.set("email", this.state.email);
        bodydata.set("gender", this.state.gender);
        bodydata.set("city_name", this.state.city);
        axios.post("/updateMyProfile",bodydata)
        .then(res =>{
            console.log(res)
            if(res.status===200){
                if(res.data.data.status===1){

                    this.props.history.push({
                        pathname:"/"
                    })
                    
                }else{
                    this.setState({error:true,loading:false})
                }
            }else{
                alert("network error")
            }
        }).catch(err =>{
            console.log(err);
        })
        this.setState({loading:false});
        
    }
    render() {
        return (
            <Card className="p-3 shadow" style={{margin:"30px 0", paddingBottom:"200px"}}>
                <Card.Title>
                    <h1 style={{margin:"10px 0", paddingLeft:"20px"}}>SignUp</h1>
                </Card.Title>
                <Card.Body>
                    <Card.Text>
                        Please Enter Your Details to Continue
                    </Card.Text>
                    <InputGroup style={{marginBottom:"10px"}}>
                        <InputGroup.Prepend>
                            <InputGroup.Text  id="basic-addon1"><img alt="name-icon" src={name} width={20} height={20}/></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="text" name="name" value={this.state.name} onChange={this.handleChange}
                            placeholder="Full Name" aria-label="Full Name"/>
                    </InputGroup>
                    <InputGroup style={{marginBottom:"10px"}}>
                        <InputGroup.Prepend>
                            <InputGroup.Text  id="basic-addon1"><img alt="email-icon" src={email} width={20} height={20}/></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="email" name="email" value={this.state.email} onChange={this.handleChange}
                            placeholder="Email" aria-label="Email"/>
                    </InputGroup>
                    <InputGroup style={{marginBottom:"10px"}}>
                        <InputGroup.Prepend>
                            <InputGroup.Text  id="basic-addon1"><img alt="location-icon" src={location} width={20} height={20}/></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="text" name="city" value={this.state.city} onChange={this.handleChange}
                            placeholder="City" aria-label="Phone Number"/>
                    </InputGroup>
                    <InputGroup style={{marginBottom:"10px"}}>
                        <InputGroup.Prepend>
                            <InputGroup.Text  id="basic-addon1"><img alt="date-icon" src={date} width={20} height={20}/></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="date" name="dob" value={this.state.dob} onChange={this.handleChange}
                        aria-label="Date of Birth"/>
                    </InputGroup>
                    <InputGroup>
                        <Form.Check inline label="Male" name="gender" value="male" type="radio" id='inline-radio-1' onChange={this.handleChange} />
                        <Form.Check inline label="Female" name="gender" value="female" type="radio" id='inline-radio-2' onChange={this.handleChange} />
                    </InputGroup>
                    <Button disabled={this.state.loading} onClick={this.handleClick} className="my-3" variant="secondary">Submit</Button>
                    <span className="float-right text-danger">{this.state.error?"Invalid Phone Number":""}</span>
                </Card.Body>
            </Card>
        )
    }
}

export default withRouter(ParentInput)