import { createGlobalStyle } from 'styled-components'
import SFPro from '../assets/fonts/SF-Pro-Display-Regular.otf'

export const GlobalStyle = createGlobalStyle`
@font-face{
  font-family:"SF-Pro";
  src:url(${SFPro});
  font-weight:normal;
  font-style:normal;
  
}
dl, dt, dd, ol, ul, li,
article, aside, canvas, details, embed, 
figure, figcaption, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {

    font-family:"SF-Pro",sans-serif;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
p,h5{
    font-family:"Montserrat",sans-serif;
}

:focus{
    outline:none;
}
`