import React, { Component } from 'react'
import Main from '../Components/Main'
import SectionFirst from '../Components/SectionFirst'
import SectionSecond from '../Components/SectionSecond'
import SectionThree from '../Components/SectionThree'
import Footer from '../Components/Footer'
import MetaTags from 'react-meta-tags'

export default class home extends Component {
    componentDidMount(){
        if(localStorage.getItem("isLogged")==='true' && localStorage.getItem('type')==='school'){
            window.location = "/schoolprofile/"+localStorage.getItem('school_id')

        }
    }
    render() {
        return (
            localStorage.getItem("isLogged")==='true' && localStorage.getItem('type')==='school'?
            ""
            :
            <React.Fragment>
            {/* <MetaTags>
                <title>FEEL | School Search Engine</title>
                <meta name="description" content="India's First School Search Engine Platform, Get Admission in best school of your locality with FEEL" />
                <meta name="keywords" content="Schools, School search, school search engine, feeleducare, Feel Schools, Best school near me"/>
            </MetaTags>  */}
            <Main />
            <SectionFirst />
            <SectionSecond />
            <SectionThree />
       <Footer/>
        </React.Fragment>
            
        )
    }
}
