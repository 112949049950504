import React, { Component } from 'react'
import { Card, InputGroup, FormControl, Button } from 'react-bootstrap'
import india from '../assets/images/svg/india.svg'
import axios from 'axios'
export default class SchoolLogin extends Component {
    state = {
        phone:"",
        error:false,
        loading:false
    }
    handleChange = e =>{
        this.setState({[e.target.name] : e.target.value})
    }
    handleClick =e=>{
        if(this.state.phone==="" || this.state.phone.length!==10){
            this.setState({error:true})
        }else{
            this.setState({error:false,loading:true})
            let data = new FormData()
            data.set("user_phone",this.state.phone)
            data.set("user_type","S")
            axios.post("/webOtp",data)
            .then(res=>{
                console.log(res)
                if(res.status===200){
                    if(res.data.data.status===1){
                        this.props.data.callback(1,res.data.data.user_id,this.state.phone)
                    }
                }else{
                    this.setState({error:true})
                }
            }).catch(err=>{
                console.log(err)
            })
            this.setState({loading:false})
        }

    }
    render() {
        return (
            <Card className="p-3 shadow">
                <Card.Title>
                    <h1>Login/SignUp</h1>
                </Card.Title>
                <Card.Body>
                    <Card.Text>
                        Please Enter Phone Number to Continue
                        </Card.Text>
                        <InputGroup>
      <InputGroup.Prepend>
        <InputGroup.Text  id="basic-addon1"><img alt="make-in-india-feeleducare" src={india} width={20} height={20}/></InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl type="tel" maxLength="10" name="phone" value={this.state.phone} onChange={this.handleChange}
        placeholder="Phone Number"
        aria-label="Phone Number"
      />
     
    </InputGroup>
    <Button disabled={this.state.loading} onClick={this.handleClick} className="my-3" variant="secondary">Verify Number</Button>
    <span className="float-right text-danger">{this.state.error?"Invalid Phone Number":""}</span>
        
                </Card.Body>
            </Card>
        )
    }
}
