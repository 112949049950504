import React, { Component } from "react";
import { Navbar, Button, NavDropdown } from "react-bootstrap";
import logo from "../../assets/images/banner.gif";
import { Link } from "react-router-dom";
import {
  SearchForm,
  InnerForm,
  InputContainer,
  InputField,
  Suggestions,
  SuggestionList,
  ListPara,
  InputSelect,
  ChoicesSelect,
  ChoicesOptions,
} from "../../Styled/SearchResults";
import axios from "axios";
import { Image } from "../../Styled/Section";
import marker from "../../assets/images/marker.png";
import { withRouter } from "react-router-dom";
import cityList from "../../assets/citys.json";
class NavbarSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      schools: [],
      classes: [],
      mediums: [],
      showAlert: false,
      selectedMedium: {
        id: null,
        name: "",
      },
      selectedClass: {
        id: null,
        name: "",
      },
      isClicked: true,
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleOutsideClick.bind(this);
  }
  componentDidMount() {
    if (this.props.selectedClass) {
      this.setState({
        selectedClass: {
          id: this.props.selectedClass.id,
          name: this.props.selectedClass.name,
        },
      });
    }
    if (this.props.selectedMedium) {
      this.setState({
        selectedMedium: {
          id: this.props.selectedMedium.id,
          name: this.props.selectedMedium.name,
        },
      });
    }
    if (this.props.query) {
      this.setState({ query: this.props.query });
    }
    window.addEventListener("mousedown", this.handleOutsideClick, false);
    axios
      .get("/getCommonData")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.status === 1) {
            this.setState({
              classes: res.data.data.classes,
              mediums: res.data.data.medium,
            });
          }
        }
      })
      .catch((err) => {});
  }
  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleOutsideClick, false);
  }
  handleOutsideClick = (ev) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(ev.target)) {
      this.setState({ schools: null, locations: null });
    }
  };
  handleChange = (ev) => {
    this.setState(
      {
        query: ev.target.value,
        isClicked: false,
      },
      this.requestSuggestions
    );
  };
  handleLogout = (e) => {
    if (e.target.innerHTML === "Logout") {
      localStorage.setItem("isLogged", false);
      localStorage.setItem("user_phone", "");
      localStorage.setItem("user_id", "");
      localStorage.setItem("school_id", "");
      localStorage.setItem("parent_id", "");
      localStorage.setItem("type", "");
      window.location = "/";
    } else if (e.target.innerHTML === "Register your School") {
      window.location = "/registerschool";
    }
  };
  handleSelectChange = (ev) => {
    this.setState({
      [ev.target.name]: {
        id: ev.target.options.selectedIndex,
        name: ev.target.value,
      },
    });
  };
  handlListClick = (ev) => {
    if (ev.target.querySelector("p")) {
      this.setState({ query: ev.target.querySelector("p").innerHTML });
    } else {
      this.setState({
        query: ev.target.innerHTML,
      });
    }
    this.setState({ schools: null, isClicked: true });
  };
  handleFormSubmit = (ev) => {
    ev.preventDefault();

    if (
      this.state.query === "" ||
      this.state.class_name === "" ||
      this.state.medium_name === "" ||
      this.state.class_name === "Class" ||
      this.state.medium_name === "Medium"
    ) {
      this.setState({ showAlert: true });
      return;
    }

    this.props.history.push({
      pathname: "/search",
      search:
        "?query=" +
        this.state.query +
        "&class=" +
        this.state.selectedClass.name +
        "&medium=" +
        this.state.selectedMedium.name,
      state: {
        selectedClass: this.state.selectedClass,
        selectedMedium: this.state.selectedMedium,
      },
    });
  };
  requestSuggestions() {
    if (this.state.query !== "") {
      let bodyFormData = new FormData();
      bodyFormData.set("keyword", this.state.query);
      axios
        .post("/getSchoolListingPaginate", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.data.status === 1 && res.data.data != null) {
              this.setState({ schools: res.data.data.school_data });
            } else {
              this.setState({ schools: null, isClicked: false });
            }
          }
        })
        .catch((err) => {
          if (!err.response) {
            console.log(err);
          }
        });
    } else {
      this.setState({ schools: null, isClicked: false });
    }
  }
  render() {
    let newarr = [];
    if (cityList && cityList.length > 0) {
      cityList.map((location, index) =>
        location.name.toLowerCase().includes(this.state.query.toLowerCase())
          ? newarr.push({ name: location.name, img: marker })
          : ""
      );
    }
    if (
      this.state.schools &&
      this.state.query &&
      this.state.schools.length > 0
    ) {
      this.state.schools.map((school, index) =>
        newarr.push({ name: school.sch_name, img: school.sch_banner })
      );
    }
    return (
      <Navbar className="shadow justify-content-between">
        <Link to="/">
          <Navbar.Brand className="py-0">
            <img src={logo} alt="feel-feeleducare-logo" width={30} height={30} />
          </Navbar.Brand>
        </Link>

        <SearchForm
          ref={this.wrapperRef}
          onSubmit={this.handleFormSubmit}
          inline
        >
          <InnerForm className="border">
            <InputContainer firstWrap>
              <InputField
                value={this.state.query}
                onChange={this.handleChange}
                placeholder="Search"
                autoComplete="off"
              />
              <Suggestions
                className="border"
                show={
                  !this.state.isClicked &&
                  newarr &&
                  newarr.length > 0 &&
                  this.state.query !== ""
                }
              >
                <ul>
                  {newarr.map((arr, index) => (
                    <SuggestionList onClick={this.handlListClick} key={index}>
                      <Image
                        className="mr-2"
                        width={30}
                        height={30}
                        src={arr.img}
                        alt={arr.name + "-img"}
                      />
                      <ListPara>{arr.name}</ListPara>
                    </SuggestionList>
                  ))}
                </ul>
              </Suggestions>
            </InputContainer>
            <InputContainer secondWrap>
              <InputSelect>
                <ChoicesSelect
                  name="selectedClass"
                  value={this.state.selectedClass.name}
                  onChange={this.handleSelectChange}
                >
                  <ChoicesOptions>Class</ChoicesOptions>
                  {this.state.classes && this.state.classes.length > 0
                    ? this.state.classes.map((clas, index) => (
                        <ChoicesOptions key={clas.id}>
                          {clas.name}
                        </ChoicesOptions>
                      ))
                    : ""}
                </ChoicesSelect>
              </InputSelect>
            </InputContainer>
            <InputContainer thirdWrap>
              <InputSelect>
                <ChoicesSelect
                  name="selectedMedium"
                  onChange={this.handleSelectChange}
                  value={this.state.selectedMedium.name}
                >
                  <ChoicesOptions>Medium</ChoicesOptions>
                  {this.state.mediums && this.state.mediums.length
                    ? this.state.mediums.map((medium, index) => (
                        <ChoicesOptions key={medium.id}>
                          {medium.name}
                        </ChoicesOptions>
                      ))
                    : ""}
                </ChoicesSelect>
              </InputSelect>
            </InputContainer>
            <Button className="text-secondary" variant="light" type="submit">
              Search
            </Button>
          </InnerForm>
        </SearchForm>

        <NavDropdown
          alignRight
          title={<span className="text-secondary">FEEL</span>}
        >
          {localStorage.getItem("isLogged") === "true" ? (
            <NavDropdown.Item
              href={
                localStorage.getItem("type") === "user"
                  ? "/admissiondetails"
                  : "/admissionstatus"
              }
            >
              My Profile
            </NavDropdown.Item>
          ) : (
            ""
          )}
          {localStorage.getItem("isLogged") === "true" &&
          localStorage.getItem("type") === "school" ? (
            <NavDropdown.Item href="/editprofile">
              Edit Profile
            </NavDropdown.Item>
          ) : (
            ""
          )}
          <NavDropdown.Item
            style={
              localStorage.getItem("isLogged") === "false"
                ? { display: "block" }
                : { display: "none" }
            }
            onClick={this.handleLogout}
          >
            {localStorage.getItem("isLogged") === "false"
              ? "Register your School"
              : ""}
          </NavDropdown.Item>
          <NavDropdown.Item target="blank" href="/newsfeed">
            News Feed
          </NavDropdown.Item>
          <NavDropdown.Item href="https://feeleducare.com/adhiraelearning">
            Adhira Elearning
          </NavDropdown.Item>
          <NavDropdown.Item href="tel:+917232807111">
            24x7 Support
          </NavDropdown.Item>
          <NavDropdown.Item
            href="https://www.feeleducare.com/about-school-ERP"
            target="blank"
          >
            About School ERP
          </NavDropdown.Item>
          <NavDropdown.Item
            style={
              localStorage.getItem("isLogged") === "true"
                ? { display: "block" }
                : { display: "none" }
            }
            onClick={this.handleLogout}
          >
            {localStorage.getItem("isLogged") === "true" ? "Logout" : ""}
          </NavDropdown.Item>
        </NavDropdown>
      </Navbar>
    );
  }
}
export default withRouter(NavbarSearch);
