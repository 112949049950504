import React, { Component } from 'react'
import { Form, Button, Jumbotron, Col, Row } from 'react-bootstrap'
import axios from 'axios'

export default class ResultInfo extends Component {
    state = {
        classes:[],
        name:"",
        percent:"",
        image:[],
        image2:{},
        class_id:null,
        arr:[],
        imagePreviewUrl:null,
        error:false,
        school_id:null
    }
    componentDidMount(){
        axios.get("/getCommonData")
        .then(res=>{
            if(res.status===200 && res.data.data.status===1){
                this.setState({classes:res.data.data.classes})
            }
        })
    }
    componentWillReceiveProps(nexprops){
        if(nexprops.ff!==this.props.ff){
            this.setState({
                school_id:nexprops.ff
            })
        }
    }
    handleChange=e=>{
        this.setState({[e.target.name]:e.target.value})
    }
    handleImage=e=>{
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
              image: file,
              image2:file,
              imagePreviewUrl: reader.result
            });
          }
          reader.readAsDataURL(file)
    }
    handleSubmit=e=>{
        e.preventDefault()
        if(this.state.arr.length>0)
        this.props.data.screen(6,this.state.arr)
        else{
            this.setState({
                error:true
            })
        }
    }
    handleClick=e=>{
        if(this.state.class_id && this.state.name!=="" && this.state.percent>0 && this.state.image.length!==0){
            this.setState({arr:[...this.state.arr,{class_id:parseInt(this.state.class_id),name:this.state.name,percent:this.state.percent,image:this.state.image,imagePreviewUrl:this.state.imagePreviewUrl}]})
            let formData = new FormData()
            formData.set("school_id",this.props.ff)
            formData.set("stud_img",this.state.image2)
            formData.set("class_id",this.state.class_id)
            formData.set("stud_name",this.state.name)
            formData.set("marks",this.state.percent)
            formData.set("subjects","PCM")
            for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }
            axios.post("/uploadToppers",formData,{headers:{ 'Content-Type': 'multipart/form-data'}})
            .then(res=>{
            console.log(res)
            }).catch(err=>{
                console.log(err.response)
            })
        }
    }
    handleSkipClick=e=>{
        this.props.data.screen(6,"")
    }
    render() {
        console.log(this.state)
        return (
            <div>
                <h1 className="text-center">Add Result</h1>
                <Form className="p-2" onSubmit={this.handleSubmit}>
                    <Form.Group>
                        {
                            this.state.arr.length>0?this.state.arr.map((item,index)=>(
                                <Jumbotron className="p-2" key={index}>
                                    <Row>
                                        <Col sm={2}>
                                    <img alt={item.name + "-img"} width={80} height={80} src={item.imagePreviewUrl}/>
                                    </Col>
                                    <Col>
                            <p>Percent: {item.percent}</p>
                            <p>Name: {item.name}</p>
                            {
                                this.state.classes.map((cl,index)=>(
                                    cl.id===item.class_id?
                                <p key={index}>Class: {cl.name}</p>:""
                                ))
                            }
                            </Col>
                            </Row>
                            </Jumbotron>
                            )):""
                        }
                    </Form.Group>
                <Form.Group>
                   
                        <Jumbotron className="p-4">
                            <Form.Control onChange={this.handleImage} accept="image/jpeg, image/png" name="image" type="file" />
                            </Jumbotron>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control name="class_id" value={this.state.class_id} onChange={this.handleChange} as="select">
                            <option>Select Class</option>
                            {
                                this.state.classes.length?this.state.classes.map((cl,index)=>(
                                <option key={index} value={cl.id}>{cl.name}</option>
                                )):""
                            }
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control onChange={this.handleChange} name="name" value={this.state.name} type="text" placeholder="Name" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control onChange={this.handleChange} value={this.state.percent} name="percent" type="number" placeholder="Percentage" />
                    </Form.Group>
                    <Form.Group>
                        <Button onClick={this.handleClick} size="sm">Add</Button>
                    </Form.Group>
                    <Form.Group>
                        <span className="text-danger">{this.state.error?"Please add result":""}</span>
                    </Form.Group>

                    <Form.Group>
                         <Button onClick={this.handleSkipClick}  variant="secondary mr-2">Skip</Button>
                         <Button type="submit" variant="secondary">Next</Button>
                     </Form.Group>
                     </Form>
            </div>
        )
    }
}
