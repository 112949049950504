import React, { Component } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { instance } from '../../utils/instance'

export default class FeesInfo extends Component {
    state = {
        school_id: this.props.ff || null,
        classes: [],
        jugClasses: [],

    }

    handleSubmit = e => {
        e.preventDefault()
        let data = []
        this.state.jugClasses.forEach((ele) => {
            if (ele.fees !== "") {
                data.push(ele)
            }
        })
        let formdata = new FormData()
        formdata.set("school_id", this.state.school_id)
        formdata.set("data", JSON.stringify(data))
        instance.post("/updatefees.php", formdata, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(res => {
                console.log(res)
                if (res.status === 200 && res.data.status === 1) {
                    alert("uploaded successfully")
                } else {
                    alert("No changes made")
                }
            })
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.data !== nextProps.data) {
            this.setState({
                school_id: nextProps.ff,
                classes: nextProps.data
            })
            let formData = new FormData()
            formData.set("school_id", nextProps.ff)
            instance.post("/getfees.php", formData)
                .then(res => {
                    console.log(res)
                    let arr = []
                    if (res.status === 200) {
                        let jug = []
                        try {
                            jug = JSON.parse(res.data.data);
                            if (this.state.classes.length > 0) {
                                for (let i = 0; i < this.state.classes.length; i++) {
                                    let fees = "";
                                    for (let j = 0; j < jug.length; j++) {
                                        if (parseInt(this.state.classes[i].class_id) === parseInt(jug[j].class_id)) {
                                            fees = jug[j].fees.toString()
                                            break;
                                        }
                                    }
                                    arr.push({ class_id: this.state.classes[i].class_id, fees: fees, class_name: this.state.classes[i].class_name })
                                }
                            }
                        } catch (err) {
                            console.log(err.response)
                        }
                        
                        // if(this.state.classes.length>0){
                        // for(let i=0;i<this.state.classes.length;i++){
                        //     let fees = "";
                        //     for(let j=0;j<jug.length;j++){
                        //       if(parseInt(this.state.classes[i].class_id)===parseInt(jug[j].class_id)){
                        //           fees = jug[j].fees.toString()
                        //           break;
                        //       }
                        //     }
                        //     arr.push({class_id:this.state.classes[i].class_id,fees:fees,class_name:this.state.classes[i].class_name})
                        // }
                    }
                    this.setState({
                        jugClasses: arr
                    })
                })
        }
    }

handleChange = (index, e) => {
    let ls = [...this.state.jugClasses]
    ls[index].fees = e.target.value
    this.setState({
        jugClasses: ls
    })
}
render() {
    console.log(this.state.jugClasses)
    return (
        <Form onSubmit={this.handleSubmit} className="p-2">
            <Form.Group>
                {
                    this.state.jugClasses.length > 0 ? this.state.jugClasses.map((cla, index) => (
                        <Row key={index} className="mb-2">
                            <Col>
                                <label>Class: {cla.class_name}</label>
                            </Col>
                            <Col>
                                <Form.Control onChange={this.handleChange.bind(this, index)} value={cla.fees} type="text" placeholder="Annual Fees" />
                            </Col>
                        </Row>
                    )) : ""
                }
            </Form.Group>
            <Form.Group>

                <Button type="submit" variant="secondary">Update</Button>
            </Form.Group>
        </Form>
    )
}
}

