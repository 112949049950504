import React, { Component } from 'react'
import Switch  from 'react-switch'
import { Form, Col, Row, Button } from 'react-bootstrap'
export default class AdmissionInfo extends Component {
    state = {
        checked:false,
        startDate:"",
        endDate:"",
        withTest:'0',
        startTime:"",
        endTime:"",
        test_type:'0',
        amount:"",
        test_time:"",
        test_date:""
    }
    handleCheckChange=checked=>{
        this.setState({checked})
    }
    handleChange=e=>{
    this.setState({[e.target.name]:e.target.value})
    }
    handleSubmit = e =>{
        e.preventDefault()
        let arr = {...this.state}
        arr.startTime = this.timeConvertor24to12(arr.startTime)
        arr.endTime = this.timeConvertor24to12(arr.endTime)
        arr.test_time = this.timeConvertor24to12(arr.test_time)
            this.props.data.screen(8,arr)
    
    }
    timeConvertor24to12(time){
        time = time.split(':')
        let min  = time[1]
        let hour = time[0]
        let am_pm = ""
        if(hour>12){
            hour = hour-12;
            am_pm="pm"
        }else{
            am_pm="am"
        }
        return hour+":"+min+" "+am_pm
    }
    render() {
        console.log(this.state)
        return (
            <div>
               <h1 className="text-center">Admission Details</h1> 
               <Form className="p-2" onSubmit={this.handleSubmit}>
                   <Form.Group>
                       <Row>
                           <Col>
                       <h4>Admission Open</h4>
                       </Col>
                       <Col>
                       <Switch className="float-right" onChange={this.handleCheckChange} checked={this.state.checked} />
                       </Col>
                       </Row>
                   </Form.Group>
                   {
                         this.state.checked?
                         <div>
                         <Form.Group>
                             <Form.Text>Start Date</Form.Text>
                         <Form.Control value={this.state.startDate} name="startDate" onChange={this.handleChange} type="date" />
                         <Form.Text>End Date</Form.Text>
                         <Form.Control value={this.state.endDate} name="endDate" onChange={this.handleChange} type="date" />
                         </Form.Group>
                         <Form.Group>
                        
                         <Form.Check checked={this.state.withTest==='1'} name="withTest" value="1" onChange={this.handleChange} inline type="radio" label="With Test"/>
                            <Form.Check checked={this.state.withTest==='0'} name="withTest" value="0" onChange={this.handleChange} inline type="radio" label="Without Test"/>
                         </Form.Group>
                         {this.state.withTest==='0'?
                         <Form.Group>
                             <Form.Label>Visit Duration</Form.Label>
                             <Form.Text>Start Time</Form.Text>
                             <Form.Control value={this.state.startTime} name="startTime" onChange={this.handleChange} type="time" />
                             <Form.Text>End Time</Form.Text>
                             <Form.Control value={this.state.endTime} name="endTime" onChange={this.handleChange} type="time" />
                         </Form.Group>:
                         <div>
                         <Form.Group>
                             <Form.Text>Test Type</Form.Text>
                             <Form.Check onChange={this.handleChange} name="test_type" checked={this.state.test_type==='1'} inline value="1" type="radio" label="Paid" />
                             <Form.Check onChange={this.handleChange} name="test_type" checked={this.state.test_type==='0'} inline value="0" type="radio" label="Free" />
                         </Form.Group>
                         {
                             this.state.test_type==='0'?
                             <div>
                             <Form.Group>
                                 <Form.Control onChange={this.handleChange} name="test_date" type="date" />
                             </Form.Group>
                             <Form.Group>
                                   <Form.Control onChange={this.handleChange}   name="test_time" type="time" />
                             </Form.Group>
                             </div>
                             :
                             <div>
                        <Form.Group>
                                 <Form.Control onChange={this.handleChange} name="test_date" type="date" />
                             </Form.Group>
                             <Form.Group>
                                   <Form.Control onChange={this.handleChange} name="test_time" type="time" />
                             </Form.Group>
                             <Form.Group>
                                 <Form.Control onChange={this.handleChange} name="amount" value={this.state.amount} placeholder="Amount" type="number" />
                             </Form.Group>
                                 </div>
                         }
                         </div>
    }
                         </div>

                         :""
                     }
                   <Form.Group>
                         <Button  variant="secondary mr-2">Back</Button>
                         <Button type="submit" variant="secondary">Submit</Button>
                     </Form.Group>

               </Form>
            </div>
        )
    }
}
