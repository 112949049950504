import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import axios from 'axios'
import Map from '../Map'
export default class ContactInfo extends Component {
    state = {
        latitude:28.7041,
        longitude:77.1025,
        states:[],
        cities:[],
        state_id:"",
        city_id:"",
        sch_address:"",
        pincode:"",
        contact_phone:"",
        email:"",
        school_id:null
    }
    componentDidMount(){
        this.position()
        axios.post("/getStates")
        .then(res=>{
            if(res.status===200 && res.data.status===1){
                this.setState({states:res.data.data})
            }
        })
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.data !== this.props.data){

        this.setState({
         state_id:nextProps.data.state_id,
         city_id:nextProps.data.city_id,
         sch_address:nextProps.data.street,
         pincode:nextProps.data.pincode,
         email:nextProps.data.email,
         contact_phone:nextProps.data.phone,
         school_id:nextProps.ff
        })
        if(nextProps.data.state_id)
        this.getCities(nextProps.data.state_id)
    }
    }
    position = async () => {
        await navigator.geolocation.getCurrentPosition(
          position => this.setState({ 
            latitude: position.coords.latitude, 
            longitude: position.coords.longitude
          }), 
          err => console.log(err)
        );
        console.log(this.state.latitude)
      }
      handleChange=e=>{
        this.setState({[e.target.name]:e.target.value})
    }
    handleSubmit=e=>{
        e.preventDefault()
if(this.state.city_id!=="Select City" && this.state.state_id!=="Select State" && this.state.sch_address!=="" && this.state.city_id!=="" && this.state.state_id!=="" && this.state.pincode.length===6 && this.state.contact_phone.length===10 && this.state.email!==""){
    let formData = new FormData()
    formData.set("school_id",this.state.school_id)
    formData.set("street_name",this.state.sch_address)
    formData.set("city_id",this.state.city_id)
    formData.set("state_id",this.state.state_id)
    formData.set("contact_email",this.state.email)
    formData.set("contact_phone",this.state.contact_phone)
    formData.set('block_name','')
    console.log(this.state.city_id)
    axios.post("/EditSchoolContactInfo",formData)
    .then(res=>{
        if(res.status===200 && res.data.status===1){
    
                alert("updated succesfully")
        }
    })
    .catch(err=>{
        console.log(err)
    })
}
    }
    handleSelectChange = e=>{
        this.setState({[e.target.name]:e.target[e.target.options.selectedIndex].value},
          )
          if(e.target.name==="state_id"){
            this.getCities(e.target[e.target.options.selectedIndex].value)
          }
    }
      getCities(state_id){

            let data = new FormData()
            data.set("state_id",state_id)
            axios.post("/getCities",data)
            .then(res=>{
                console.log(res)
                if(res.status===200 && res.data.status===1){
                    this.setState({cities:res.data.data})
                }
            })

      }
    render() {
        return (
            
            <Form className="p-2" onSubmit={this.handleSubmit}>
                    <Form.Group>
                        <Form.Control onChange={this.handleChange} value={this.state.sch_address}  name="sch_address" placeholder="Address" type="text" required/>
                    </Form.Group>
                    <Form.Group>
                        <Map latitude={this.state.latitude} longitude={this.state.longitude} />

                    </Form.Group>
                    <Form.Group>
                        <Form.Control value={this.state.state_id} name="state_id" as="select" onChange={this.handleSelectChange}>
                            <option>Select State</option>
                            {this.state.states?this.state.states.map((st,index)=>(
                                <option value={st.state_id} key={index}>{st.state_name}</option>
                            )):""}
                        </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control value={this.state.city_id} name="city_id" onChange={this.handleSelectChange} as="select">
                                <option>Select City</option>
                                {this.state.cities?this.state.cities.map((city,index)=>(
                                    <option value={city.city_id} key={index}>{city.city_name}</option>
                                )):""}
                            </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control value={this.state.pincode} name="pincode" onChange={this.handleChange} type="tel" maxLength="6" placeholder="Pincode" required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control value={this.state.email} name="email" onChange={this.handleChange} type="email" placeholder="Email" required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control value={this.state.contact_phone} onChange={this.handleChange} name="contact_phone" type="tel" maxLength="10" placeholder="Contact Number" required/>
                    </Form.Group>
                    <Form.Group>
                        <Button type="submit" variant="secondary">Update</Button>
                    </Form.Group>
                </Form>
        )
    }
}
