import React, { Component } from 'react'
import { Media, Button } from 'react-bootstrap'
import axios from 'axios'
import { Image } from '../Styled/Section';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faSync } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import '../Styled/style.css';

export default class ResultList extends Component {
    
    state = {
        query: "",
        class: null,
        medium: null,
        maxfees: null,
        rating: null,
        schools: [],
        isLoading: false,
        page: 0,
        pages: 3
    }
    componentDidMount(){
        console.log("Called from here");
        console.log(this.props);
    this.getResults(this.props.query, this.props.classs, this.props.medium, this.state.page, this.props.rating, this.props.maxFees)
    }
    getResults(query, classs, medium, page, rating, maxfees){
        let bodyFormData = new FormData();
        bodyFormData.set('keyword',query.toString())
        bodyFormData.set('class_id',classs)
        bodyFormData.set('medium_id',medium)
        bodyFormData.set('page',page);
        if(rating !== undefined && rating !== null && rating !== 1) bodyFormData.set('rating', rating)
        if(maxfees !== undefined && maxfees !== null && maxfees !== 30000) bodyFormData.set('max_fees', maxfees)
        for (var pair of bodyFormData.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }
        this.setState({
            isLoading: true
        })
            axios.post("/getSchoolListingPaginate",bodyFormData)
            .then(res=>{
                console.log(res.data.data)
                if(res.status===200){
                    if(res.data.data.status===1){
                        const schools = res.data.data.school_data;
                        const items = [];
                        for(var i=0; i<schools.length; i++) {
                            const school = schools[i];
                            var name = school.sch_name;
                            name = name.replaceAll(" ", "-");
                            var schoolLink = school.school_id+'-'+name+'-'+school.city_name;
                            school["schoolLink"] = schoolLink;
                            items.push(school)
                        }
                        this.setState({
                            schools:items,
                            pages:res.data.data.length/10
                        })
                    }else{
                        this.setState({
                            schools:[],
                        })
                    }
                }
                this.setState({ isLoading: false })
            }).catch(err=>{
                this.setState({ isLoading: false })
                console.log(err.response)
                // const data = err.response;
                // const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                //     JSON.stringify(data)
                //   )}`;
                //   const link = document.createElement("a");
                //   link.href = jsonString;
                //   link.download = "data.json";
              
                //   link.click();
            })
    }
    componentWillReceiveProps(nexProps){
        console.log("this is triggered");
        this.setState({query : nexProps.query});
        console.log(nexProps);
        this.getResults(nexProps.query, nexProps.classs, nexProps.medium, this.state.page, nexProps.rating, nexProps.maxFees)    
    }
    handlePageClick = (event) => {
        const pageNo = event.selected;
        this.getResults(this.props.query, this.props.classs, this.props.medium, pageNo, this.props.rating, this.props.maxFees)
        this.setState({
            page:pageNo
        })    
    }
    render() {
        const pages = this.state.pages;
        return (
            <React.Fragment>
                <ul className="list-unstyled">
                {
                    !this.state.isLoading && this.state.schools && this.state.schools.length>0?
                    this.state.schools.map((school,index)=>(
                        <div key={index} >
                            <Media as="li" className="mb-4">
                                <Image alt={school.sch_name + "-banner"} src={school.sch_banner} width={300} height={200} widthSm={150} heightSm={100} />
                                <Media.Body className="ml-2">
                            <Link className="text-dark" to={{pathname:"/schoolprofile/"+school.schoolLink,state:{class_id:this.props.classs,medium_id:this.props.medium}}}><h4>{school.sch_name}</h4></Link>
                    <p>{school.street_name}</p>
                    {
                            (()=>{
                                let items=[];
                                for(let i=1; i<=school.total_rating;i++)
                               items.push(<FontAwesomeIcon key={i} icon={faStar} style={{color:"orange"}}/>)
                                
                                for(let i=school.total_rating;i<5;i++){
                                   items.push(<FontAwesomeIcon className="text-secondary" key={i} icon={faStar}/>)
    
                                }
                            return items
                            }

                            )()
                            
                        }

                        <p className="mt-3">{"Fees: "+(school.min_max_fees!==""?school.min_max_fees : school.min_fees + "-" + school.max_fees)}</p>
                        <div className="float-right">
                        <Link to={{pathname:"/schoolprofile/"+school.schoolLink, state:{class_id:this.props.classs,medium_id:this.props.medium}}}><Button variant="outline-secondary" className="btn-outline-dark shadow-sm mr-2">View Details</Button></Link>
                        <Link to={"/admission/"+school.school_id}><Button variant="secondary" className="shadow-sm">Admission</Button></Link>
                        </div>
                                </Media.Body>
                            </Media>
                            <hr/>
                        </div>
                    )):this.state.isLoading?<h4 className='text-center'>Please wait...<FontAwesomeIcon icon={faSync} spin></FontAwesomeIcon></h4>:<h2 className="text-center">Not Found</h2>
                }
                </ul>
                <ReactPaginate style={this.state.isLoading?{display:"none"}:{}}
                    previousLabel={'prev'}
                    nextLabel={'next'}
                    pageCount={pages}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </React.Fragment>
        )
    }
}
