import React, { Component } from 'react'
import NavbarSearch from '../Components/Navbar/NavbarSearch'
import Footer from '../Components/Footer'
import { Container, Row, Col } from 'react-bootstrap'
import ResultList from '../Components/ResultList';
import Filters from '../Components/Filters';
import MetaTags from 'react-meta-tags'

export default class searchResults extends Component {
    state = {
        class_id:this.props.location.state.selectedClass.id || null,
        medium_id:this.props.location.state.selectedMedium.id || null,
        page:0
    }
    
    render() {
        const searchParms=new URLSearchParams(this.props.location.search);
        const keyword= searchParms.get('query');
        const medium =searchParms.get('medium');
        const classs = searchParms.get('class');
        let mediumId =this.props.location.state.selectedMedium.id;
        let classId =this.props.location.state.selectedClass.id;
        var maxfees = 30000;
        var rating = 1;
        if(this.props.location.state.maxFees !== undefined) {
            maxfees = this.props.location.state.maxFees
        }
        if(this.props.location.state.rating !== undefined) {
            rating = this.props.location.state.rating
        }
        return (
            <div>
                <MetaTags>
                    <title>FEEL | School Search Engine</title>
                    <meta name="description" content="Login page Feel, Feel Login, Feel Signup, Feel School Search engine, best school near me" />
                    <meta name="keywords" content="Feel Login, Feel educare Login, Feel Signup, schools near me, best schools near me , schools in Rajasthan, Schools in Jaipur" />
                    {/* <meta property="og:image" content="path/to/image.jpg" /> */}
                </MetaTags>
                <NavbarSearch query={keyword} selectedClass={this.props.location.state?this.props.location.state.selectedClass:null} selectedMedium={this.props.location.state? this.props.location.state.selectedMedium:null} />
                <Container className="mt-3" fluid>
                <Row className="py-4">
                    <Col>
                    </Col>
                    <Col sm={12} md={9}>
                    <h4>{"Showing results for schools in \""+keyword+"\" with medium \""+ medium+"\" for class \""+classs+"\""}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={3}>
                        <Filters query={keyword} classs={classs} classId={classId} mediumId={mediumId} medium={medium} pageNo={this.state.page}/>
                    </Col>
                    <Col sm={12} md={9}>
                        <ResultList query={keyword} classs={classId} medium={mediumId} pageNo={this.state.page} maxFees={maxfees} rating={rating}/>
                    </Col>
                </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}
