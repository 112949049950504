import React, { Component } from 'react'
import NavbarDefault from '../Components/Navbar/NavbarDefault'
import Footer from '../Components/Footer'
import Admission from '../Components/Admission'
import {withRouter} from 'react-router-dom'

class admission extends Component {
    componentDidMount(){
        if(localStorage.getItem("isLogged")==='true'){
            if(localStorage.getItem('type')==='school'){
                this.props.history.push({pathname:"/"})
            }
        }else{
            this.props.history.push({pathname:"/login"})
        }
    }
    render() {
        return (
            <React.Fragment>
                <NavbarDefault/>
                <Admission id={this.props.match.params.id}/>
                <Footer/>
            </React.Fragment>
        )
    }
}
export default withRouter(admission)