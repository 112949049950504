import React, { Component } from 'react'
import success from '../../assets/images/tick.svg'
import { Button } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
class Success extends Component {
    state= {
        school_id:this.props.ff || null
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.ff !== this.props.ff){
            this.setState({
                school_id:nextProps.ff
            })
        }
    }
    handleClick=e=>{
        this.props.history.push("/schoolprofile/"+this.state.school_id)
    }
    render() {
        return (
            <div className="p-4 text-center">
                <img src={success} width={60} alt="success-img-feeleducare"/>
                <h2>Congratulations!</h2>
                <p>Your school is now registered with FEEL</p>
                <Button onClick={this.handleClick} variant="secondary">Okay</Button>
            </div>
        )
    }
}
export default withRouter(Success)