import React, { Component } from 'react'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import logo from '../../assets/images/banner.gif'
import { Link } from 'react-router-dom'
export default class NavbarDefault extends Component {
    state = {
        isLogged:false,
        user_type:"",
        phone:""

    }
 componentDidMount(){
     if(localStorage.getItem("isLogged")==='true'){
         this.setState({isLogged:true,user_type:localStorage.getItem("type"),phone:localStorage.getItem("school_phone")})

     }else{
         this.setState({isLogged:false})
     }

 }
 handleLogout = (e) =>{
     if(e.target.innerHTML==="Logout"){
      localStorage.setItem("isLogged",false);
      localStorage.setItem("user_phone","");
      localStorage.setItem("user_id","");
      localStorage.setItem("school_id","");
      localStorage.setItem("parent_id","");
      localStorage.setItem("type","");
      window.location = "/"
     }else if(e.target.innerHTML === "Register your School"){
         window.location.href = "/registerschool"
     }
  }
    render() {
        return (
            <Navbar bg="white" variant="light" className="shadow">
                <Container>
                   <Nav.Link href={this.state.isLogged?"/":"/login"} className="text-secondary py-0">{this.state.isLogged?'Welcome':"Login/Signup"}</Nav.Link>
                    <Link to="/"><Navbar.Brand className="py-0"><img src={logo} alt="feel-feeleducare-logo" width={30} height={30} /></Navbar.Brand></Link>
                    {/*!this.state.isLogged?<Nav.Link href="/schoollogin" className="text-secondary py-0">Login School</Nav.Link>:
                                       <NavDropdown  className="text-secondary" title={ <span className="text-secondary py-0">Dropdown</span>}>
                                       <NavDropdown.Item href={this.state.isLogged?this.state.user_type==='user'?"/admissiondetails":"/admissionstatus":""}>Admissions</NavDropdown.Item>
                                       <NavDropdown.Item href={this.state.isLogged && this.state.user_type==="school"?"tel:"+this.state.phone:""}>{this.state.isLogged && this.state.user_type==='school'?"Support 24x7":""}</NavDropdown.Item>
                                       <NavDropdown.Item onClick={this.handleLogout}>Logout</NavDropdown.Item>
                                   </NavDropdown>
                                   */
                                  <NavDropdown alignRight className="text-secondary py-0" title={<span className="text-secondary">FEEL</span>}>
                                     {
                                         this.state.isLogged?<NavDropdown.Item  href={this.state.user_type==='user'?'/myprofile':'/admissionstatus'} >My Profile</NavDropdown.Item>:""
                                     }
                                     {
                                         this.state.isLogged && this.state.user_type==='school'?
                                         <NavDropdown.Item href="/editprofile">Edit Profile</NavDropdown.Item>:""
                                     }
                                     <NavDropdown.Item style={!this.state.isLogged?{display:"block"}:{display:"none"}} onClick={this.handleLogout}>{!this.state.isLogged?"Register your School":""}</NavDropdown.Item> 
                                     <NavDropdown.Item target="blank" href="/newsfeed">News Feed</NavDropdown.Item>
                                     <NavDropdown.Item href="https://feeleducare.com/adhiraelearning">Adhira Elearning</NavDropdown.Item>
                                     <NavDropdown.Item href="tel:+917232807111">Support 24x7</NavDropdown.Item>

<NavDropdown.Item href="https://feeleducare.com/about-school-ERP" target="blank">About School ERP</NavDropdown.Item>
<NavDropdown.Item style={this.state.isLogged?{display:"block"}:{display:"none"}} onClick={this.handleLogout}>{this.state.isLogged?"Logout":""}</NavDropdown.Item> 
</NavDropdown>
                                   

                    }
 
                </Container>
            </Navbar>
        )
    }
}
