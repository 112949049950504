import React, { Component } from 'react'
import NavbarDefault from './Navbar/NavbarDefault';
import logo from '../assets/images/banner.gif'
import SearchBar from './SearchBar';
import 'bootstrap/dist/css/bootstrap.min.css'
import {Logo, FullContainer,Slogan, Maindiv} from '../Styled/Hero'
export default class Main extends Component {
    render() {
        return (
            <Maindiv>
                  <NavbarDefault />
                  <FullContainer>
                  <Logo  src={logo} alt="feel-educare-logo" />
                  <Slogan className="mt-4">Find the Best School in your city!</Slogan>
                  <SearchBar />
                  </FullContainer>
            </Maindiv>
        )
    }
}
