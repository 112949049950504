import React, { Component } from "react";
import { Form, Jumbotron, Button, Card } from "react-bootstrap";
import axios from "axios";
export default class ResultInfo extends Component {
  state = {
    classes: [],
    name: "",
    percent: "",
    image: {},
    class_id: null,
    toppers: [],
    school_id: null,
  };
  // componentDidMount(){
  //     axios.get("/getCommonData")
  //     .then(res=>{
  //         if(res.status===200 && res.data.data.status===1){
  //             this.setState({classes:res.data.data.classes})
  //         }
  //     })
  // }
  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({
        toppers: nextProps.data,
        classes: nextProps.classes,
        school_id: nextProps.ff,
      });
    }
  }
  handleRemove = (id, e) => {
    this.props.callback(id);
  };
  handleImage = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleClick = (e) => {
    if (this.state.percent !== "" && this.state.class_id && this.state.image) {
      let formData = new FormData();
      formData.set("school_id", this.state.school_id);
      formData.set("class_id", this.state.class_id);
      formData.append("stud_img", this.state.image);
      formData.set("stud_name", this.state.name);
      formData.set("marks", this.state.percent);
      formData.set("subjects", "PCM");
      axios
        .post("/uploadToppers", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200 && res.data.status === 1) {
            this.props.callback("");
            alert(res.data.message);
          }
        })
        .catch((err) => {
          alert("could not update");
        });
    }
  };
  render() {
    console.log(this.state.toppers);
    return (
      <React.Fragment className="text-center">
        {this.state.toppers && this.state.toppers.length > 0
          ? this.state.toppers.map((topper, index) => (
              <span>
                <p>{topper.title}</p>
                {topper.result.map((innerTopper, index) => (
                  <Card
                    key={index}
                    className="shadow p-2 m-2"
                    style={{ width: "7rem", display: "inline-block" }}
                  >
                    <Card.Img
                      className="rounded-circle"
                      variant="top"
                      src={innerTopper.stud_img}
                      alt={innerTopper.stud_name + "-topper-img"}
                    />
                    <Card.Body className="p-0 text-center">
                      <Card.Text className="mb-0">
                        {innerTopper.stud_name}
                      </Card.Text>
                      <Card.Text className="mb-0">
                        {innerTopper.marks}%
                      </Card.Text>
                      <small
                        onClick={this.handleRemove.bind(this, innerTopper.id)}
                        className="text-danger"
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        Remove
                      </small>
                    </Card.Body>
                  </Card>
                ))}
              </span>
            ))
          : ""}
        <Form className="p-2">
          <Form.Group>
            <Jumbotron className="p-4">
              <Form.Control
                onChange={this.handleImage}
                accept="image/jpeg, image/png"
                name="image"
                type="file"
              />
            </Jumbotron>
          </Form.Group>
          <Form.Group>
            <Form.Control
              name="class_id"
              value={this.state.class_id}
              onChange={this.handleChange}
              as="select"
            >
              <option>Select Class</option>
              {this.state.classes !== undefined && this.state.classes.length
                ? this.state.classes.map((cl, index) => (
                    <option key={index} value={cl.id}>
                      {cl.class_name}
                    </option>
                  ))
                : ""}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Control
              onChange={this.handleChange}
              name="name"
              value={this.state.name}
              type="text"
              placeholder="Name"
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              onChange={this.handleChange}
              value={this.state.percent}
              name="percent"
              type="number"
              placeholder="Percentage"
            />
          </Form.Group>
          <Form.Group>
            <Button variant="secondary" onClick={this.handleClick} size="sm">
              Add
            </Button>
          </Form.Group>
        </Form>
      </React.Fragment>
    );
  }
}
