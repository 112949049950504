import React, { Component } from 'react'
import { Form, Button, Jumbotron } from 'react-bootstrap'
import axios from 'axios';

export default class SchoolInfo extends Component {
    state = {
        school_id:null,
        sch_banner:{},
        sch_name:"",
        board_id:null,
        sch_type:"",
        marking_id:"",
        sch_about:"",
        selectedBoard:"",
        error:false,
        medium_ids:"",
    }

    componentWillReceiveProps(nextProps) {
       
        if (nextProps.data !==this.props.data) {
          this.setState({ sch_name: nextProps.data.schname ,sch_banner:nextProps.data.sch_banner,
            selectedBoard:nextProps.data.board,board_id:nextProps.data.board_ids,
            sch_type:nextProps.data.school_type_id.toString(),marking_id:nextProps.data.marking_ids,
            sch_about:nextProps.data.about,medium_ids:nextProps.data.medium_ids,school_id:nextProps.data.school_id});
        }
      }
    componentDidMount(){
    
    }
    handleSubmit =e=>{
        e.preventDefault()
        let data = new FormData()
        data.set("school_id",this.state.school_id)
        data.append ("sch_banner",this.state.sch_banner)
        data.set("sch_name",this.state.sch_name);
         data.set("about_school",this.state.sch_about)
         data.set("board_ids",this.state.board_id)
         data.set("school_type_id",this.state.sch_type)
         data.set("marking_ids",this.state.marking_id)
         data.set("medium_ids",this.state.medium_ids)
        // for (var pair of data.entries()) {
        //     console.log(pair[0]+ ', ' + pair[1]); 
        // }
        axios.post("/addEditSchoolInfo",data,{headers:{ "Content-Type": "multipart/form-data" }})
        .then(res=>{
            console.log(res)
            if(res.status===200 && res.data.status===1){
                alert("updated successfully")
            }else{
                alert("update error!")
            }
        }).catch(err=>{
            if(err.response.status===401){
              alert("Please choose image")
            }
        })
    }
    handleFileChange=e=>{
        this.setState({[e.target.name]:e.target.files[0]})
    }
    handleChange=e=>{
        this.setState({[e.target.name]:e.target.value})
        if(e.target.name==="selectedBoard"){
            this.setState({board_id:e.target.options.selectedIndex})
        }
    }
    handleCheckBox=e=>{
        let arr = this.state.medium_ids.split(",")
      
if(e.target.checked){
    let filtered = arr.filter(el=>{
        return el !==""
    })
    arr=filtered
    arr.push(e.target.value)
    arr.sort()
    console.log(arr)
    this.setState({medium_ids:arr.join(",")})
}else{
    arr.splice(arr.indexOf(e.target.value),1)
    this.setState({medium_ids:arr.join(",")})
}


console.log(this.state.medium_ids)
    }
    render() {
        return (
            <Form className="p-4" onSubmit={this.handleSubmit}>
            <Form.Group>
                <Jumbotron style={{backgroundPosition:"center",backgroundSize:"cover",backgroundImage:'url('+this.state.sch_banner+')'}}>
                <Form.Control className="text-white" name="sch_banner" onChange={this.handleFileChange} accept="image/png, image/jpeg, image/jpg" type="file"  />
                </Jumbotron>
            </Form.Group>
            <Form.Group>
                <Form.Control  value={this.state.sch_name} name="sch_name" onChange={this.handleChange} type="text" placeholder="Name of the School" />
            </Form.Group>
            <Form.Group>
            <Form.Check onChange={this.handleCheckBox} inline checked={this.state.medium_ids==="1" || this.state.medium_ids==="1,2"} value="1" type="checkbox" label={"Hindi Medium"} />
            <Form.Check onChange={this.handleCheckBox} inline checked={this.state.medium_ids==="2" || this.state.medium_ids==="1,2"}  value="2" type="checkbox" label={"English Medium"} />
            
            </Form.Group>
            <Form.Group>
                <Form.Control name="selectedBoard" value={this.state.selectedBoard} onChange={this.handleChange} as="select">
                    <option>Select Board</option>
                    <option key="1">CBSE</option>
                    <option key="2">RBSE</option>
                    <option key="3">ICSE</option>
                    <option key="4">N.C.V.T</option>
                    <option key="5">Other</option>
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>School Type</Form.Label><br/>
                <Form.Check checked={this.state.sch_type==="1"} onClick={this.handleChange} name="sch_type" value="1" inline type={"radio"} label="Boys"/>
                <Form.Check checked={this.state.sch_type==="2"} onClick={this.handleChange} name="sch_type" value="2" inline type={"radio"} label="Girls" />
                <Form.Check checked={this.state.sch_type==="3"} onClick={this.handleChange} name="sch_type" value="3" inline type={"radio"} label="Both"/>
            </Form.Group>
            <Form.Group>
            <Form.Label>Marking System</Form.Label><br/>
                <Form.Check checked={this.state.marking_id==="1"}  onClick={this.handleChange} name="marking_id" value="1" inline type={"radio"} label="Marks" />
                <Form.Check checked={this.state.marking_id==="2"} onClick={this.handleChange} name="marking_id" value="2" inline type={"radio"} label="Grades" />
            </Form.Group>
            <Form.Group>
                <Form.Label>About</Form.Label>
                <Form.Control name="sch_about" value={this.state.sch_about} onChange={this.handleChange} as="textarea"/>
            </Form.Group>
            <Form.Group>
        <span className="text-danger">{this.state.error?"please fill the empty field":""}</span>
            </Form.Group>
            <Form.Group>
            <Button type="submit" variant="secondary">Update</Button>
            </Form.Group>
        </Form>
        )
    }
}
