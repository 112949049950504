import React, { Component } from 'react'
import { Form, Jumbotron, Button } from 'react-bootstrap'
import axios from 'axios'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class PhotosInfo extends Component {

    state = {
        images:[],
        photo:{},
        school_id:null
            }
            componentWillReceiveProps(nextProps){
                if(this.props.data!==nextProps.data){

                    this.setState({
                        school_id:nextProps.ff,
                        images:nextProps.data
                    })
                }
            }
            handleChange=e=>{
                this.setState({[e.target.name]:e.target.files[0]})
            }
            handleSubmit=e=>{
                e.preventDefault()
                let formdata = new FormData()
                formdata.set("school_id",this.state.school_id)
                formdata.set("img_name",this.state.photo)
                if(this.state.photo){
                    axios.post("/uploadSchoolImages",formdata,{headers:{ 'Content-Type': 'multipart/form-data'}})
                    .then(res=>{
                        if(res.status===200 && res.data.status===1){
                            this.props.callback()
                            alert("update successfully")
                        }

                    })
                    .catch(err=>{
                        console.log(err.response)
                         if(err.response.status===401){
                            alert("image size should be less than 5Mb")
                        }
                    })
                }

            }
            handleDelete=(index,img_id,e)=>{
                let formData= new FormData()
                formData.set("school_id",this.state.school_id)
                formData.set("img_id",img_id)
                axios.post("/removeImages",formData)
                .then(res=>{
                    console.log(res)
                    if(res.status===200){
                        if(res.data.status===1){
                        let list = [...this.state.images]
                        list.splice(index,1)
                        this.setState({
                            images:list
                        })
                    }else{
                        alert("could not delete")
                    }
                    }
                })
            }
    render() {
        console.log(this.state.images)
        return (
            <Form className="p-2" onSubmit={this.handleSubmit}>
                <Form.Group>
                    {
                        this.state.images && this.state.images.length>0?
                        this.state.images.map((image,index)=>(
                            <Jumbotron style={{position:"relative"}} className="p-0">
                                  <FontAwesomeIcon onClick={this.handleDelete.bind(this,index,image.img_id)} style={{cursor:"pointer",position:"absolute",right:"5%",top:"50%",transform:"translateY(-50%)"}} color="red" icon={faTrash} />
                                <img style={{width:"100%",height:"200px"}} src={image.img_name} alt="feel-school-img"/>
                              
                            </Jumbotron>
                        )):""
                    }
                </Form.Group>
            <Form.Group>
                <Jumbotron>
                    <Form.Control name="photo" onChange={this.handleChange} type="file" accept="image/jpeg, image/png" />
                </Jumbotron>
            </Form.Group>
            <Form.Group>
                 <Button type="submit" variant="secondary">Add</Button>
             </Form.Group>
        </Form>
        )
    }
}
