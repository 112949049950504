import React, { Component } from 'react'
import { Form, Jumbotron, Button } from 'react-bootstrap'

export default class PhotosInfo extends Component {
    state = {
photo:{},
error:false
    }
    handleChange = e =>{
        this.setState({photo:e.target.files[0]})
    }
    handleSubmit =e =>{
        e.preventDefault()
        if(this.state.photo){
            this.setState({
                error:false
            })
        this.props.data.screen(7,this.state.photo)
        }
        else
        this.setState({
            error:true
        })
    }
    render() {
        console.log(this.state.photo)
        return (
            <div>
                <h1 className="text-center">Upload Photo</h1>
                <Form className="p-2" onSubmit={this.handleSubmit}>
                    <Form.Group>
                        <Jumbotron>
                            <Form.Control onChange={this.handleChange} type="file" accept="image/jpeg, image/png" />
                        </Jumbotron>
                    </Form.Group>
                    <Form.Group>
        <span className="text-danger">{this.state.error?"please select image":""}</span>
                    </Form.Group>
                    <Form.Group>
                         <Button type="submit" variant="secondary">Next</Button>
                     </Form.Group>
                </Form>
            </div>
        )
    }
}
