import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import Login from '../Components/Login'
import Footer from '../Components/Footer'
import NavbarDefault from '../Components/Navbar/NavbarDefault'
import studentlogin from '../assets/images/studentlogin.webp'
import { Maindiv } from '../Styled/Hero'
import {FullContainer} from '../Styled/Login'
import Verify from '../Components/Verify'
import MetaTags from 'react-meta-tags'

export default class login extends Component {
    state={
        newUser:false,
        isVerify:false,
        user_id:"",
        user_phone:null
    }
    mycallback = (isNewUser,data,user_id,phone) =>{
        this.setState({
            isVerify:data,
            user_id:user_id,
            user_phone:phone,
            newUser:isNewUser
        })
    }
    render() {
        let comp = "";
        if(this.state.isVerify){
          comp =   <Verify data={{newUser:this.state.newUser ,user_id:this.state.user_id, user_phone:this.state.user_phone}} />
        }else{
            comp = <Login data={{callback:this.mycallback.bind(this)}}/>
        }
        return (
           <Maindiv>
                <MetaTags>
                    <title>Login • Feel</title>
                    <meta name="description" content="Login page Feel, Feel Login, Feel Signup, Feel School Search engine, best school near me" />
                    <meta name="keywords" content="Feel Login, Feel educare Login, Feel Signup, schools near me, best schools near me , schools in Rajasthan, Schools in Jaipur" />
                    {/* <meta property="og:image" content="path/to/image.jpg" /> */}
                </MetaTags>
                <NavbarDefault/>
                <FullContainer style={{height:"80%"}} alt="feeleducare-login" bg={studentlogin} fluid>
                    <Row className="my-auto">
                    <h1  style={{margin:"10px 0", padding:"10px 0"}}>
                        India's First School Search Engine
                    </h1>
                    </Row>
                    <Row className="my-auto" style={{width:"100%", height:"100%"}}>
                        <Col xs={12} md={5}>
                            {comp}
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                </FullContainer>
                <Footer />
            </Maindiv>
        )
    }
}