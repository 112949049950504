import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import axios from 'axios'
export default class ClassInfo extends Component {
    state = {
        classes:[],
        clasData:[],
        class_id:[],
        selectedClass:[],
        error:false,
        school_id:null
        }
        componentDidMount(){
            axios.get("/getCommonData")
            .then(res=>{
                if(res.status===200){
                    if(res.data.data.status===1){
                        this.setState({
                            classes:res.data.data.classes
                        })
                    }
                }
            })
            }
            handleSubmit=e=>{
                e.preventDefault()
                if(this.state.selectedClass.length>0){
                    let list = []
                this.state.selectedClass.forEach((ele,index)=>{
                    list.push({class_id:ele,subject_ids:1,section_ids:1})
                })
                this.state.clasData.push(list)
                let formData = new FormData()
                formData.set("school_id",this.state.school_id)
                let array =[]
                  this.state.clasData.map((val)=>(
                    array.push(JSON.stringify(val))
                ))
                  let  json =array.join(",")
                  formData.set("clasData",json)
                  let ls = [...this.state.class_id]
                  this.state.selectedClass.forEach((ele)=>{
                      ls.push(parseInt(ele))
                  })
                axios.post("/addSchoolClassInfo",formData)
                .then(res=>{
                    console.log(res)
                    if(res.status===200){
                        if(res.data.status===1){
                            this.setState({
                                class_id:ls,
                                selectedClass:[],
                               
                            })
                            alert("updated successfully")

                        }
                    }
                }).catch(err=>{
                    console.log(err.response)
                })
                }else{
                this.setState({
                    error:true
                })
                }
                }
            componentWillReceiveProps(nextProps){
                if(this.props.data!==nextProps.data){
                    let list =[]
            
                    nextProps.data.map((cl,index)=>(
                        list.push(cl.class_id)
                    ))
                    this.setState({
                        class_id:list,
                        school_id:nextProps.ff
                    })
                }
                
            }
            handleChange=e=>{
                if(e.target.checked){
                    this.setState({
                        [e.target.name]:[...this.state.selectedClass,e.target.value]
                    })
                }else{
                    let list = [...this.state.selectedClass]
                    list.splice(list.indexOf(e.target.value),1)
                    this.setState({
                        selectedClass:list
                    })
                }
            }
    render() {
        console.log(this.state.selectedClass)
        let content = []
        for(let i=0;i<this.state.classes.length;i++){
            let flag= true;
            for(let j=0;j<this.state.class_id.length;j++){

                if(this.state.class_id[j]===this.state.classes[i].id){
                    flag=false
                }
            }
            if(flag)
            content.push(<Form.Check value={this.state.classes[i].id} name="selectedClass" key={i} onChange={this.handleChange}  label={this.state.classes[i].name}/>)
        }

        return (
            <div>
                 <Form className="p-2" onSubmit={this.handleSubmit}>
                     <Form.Group>
                {content}
                     </Form.Group>
                     <Form.Group>
                        <span className="text-danger">{this.state.error?"please select class":""}</span>
                     </Form.Group>
                     <Form.Group>
                         <Button type="submit" variant="secondary">Add</Button>
                     </Form.Group>
                 </Form>
            </div>
        )
    }
}
