import React, { Component } from 'react'
import { Container, Tabs, Tab, Row, Col } from 'react-bootstrap'
import NavbarDefault from '../Components/Navbar/NavbarDefault'
import axios from 'axios'
import MyProfile from '../Components/MyProfile'
import AdmissionStatusParent from '../Components/AdmissionStatusParent'
import Footer from '../Components/Footer'

export default class admissionDetails extends Component {
    state = {
        adData:[]
    }
    componentDidMount(){
        let formdata = new FormData()
        console.log(localStorage.getItem("parent_id"));
        formdata.set("parent_id",localStorage.getItem("parent_id"))
        axios.post("/getAdmissionRequests",formdata)
        .then(res=>{
            console.log(res)
            if(res.status===200){
                if(res.data.data.status===1){
                    this.setState({adData:res.data.data.adData})
                }
            }
        }).catch(err=>{
            console.log(err.response);
        })
    }
    render() {
        let profileContent = "";
        profileContent = <MyProfile />
        return (
            <div>
                <NavbarDefault/>
                <Container className="mt-3" fluid>
                <Row>
                    <Col sm={12} md={3} lg={4}>
                    {profileContent}
                    </Col>
                    <Col sm={12} md={9} lg={8}>
                    <Container className="mt-5">
                            <Tabs>
                                <Tab eventKey="ongoing" title="Ongoing">
                                    <AdmissionStatusParent tabName="Pending" data={this.state.adData}/>
                                </Tab>
                                <Tab eventKey="completed" title="Completed">
                                    <AdmissionStatusParent tabName="Completed" data={this.state.adData}/>
                                </Tab>
                                <Tab eventKey="cancelled" title="Cancelled">
                                    <AdmissionStatusParent tabName="Cancelled" data={this.state.adData}/>
                                </Tab>
                            </Tabs>   
                        </Container>
                    </Col>
                </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}
