import React, { Component } from 'react'
import NavbarDefault from '../Components/Navbar/NavbarDefault'
import { Tabs, Tab, Container } from 'react-bootstrap'
import SchoolInfo from '../Components/updateProfile/SchoolInfo'
import Footer from '../Components/Footer'
import axios from 'axios'
import ContactInfo from '../Components/updateProfile/ContactInfo'
import PhotosInfo from '../Components/updateProfile/PhotosInfo'
import ClassInfo from '../Components/updateProfile/ClassInfo'
import FacilityInfo from '../Components/updateProfile/FacilityInfo'
import ResultInfo from '../Components/updateProfile/ResultInfo'
import AdmissionInfo from '../Components/updateProfile/AdmissionInfo'
import FeesInfo from '../Components/updateProfile/FeesInfo'
import MetaTags from 'react-meta-tags'

export default class editprofile extends Component {
    state = {
        school_id:localStorage.getItem('school_id') || null,
        basic_info:[],
        contact_info:[],
        class_section:[],
        facilities:[],
        admission_info:{},
        toppers:[],
        images:[]
    }
    componentDidMount(){
       if(localStorage.getItem("school_id")===""){
           alert("Login First")
           return
       }
        let data = new FormData()
        data.set("school_id",this.state.school_id)
        axios.post("/getSchoolProfileData",data)
        .then(res=>{
            console.log(res)
            if(res.status===200 && res.data.status===1){
                this.setState({basic_info:res.data.data.basic_info,
                    contact_info:res.data.data.contact_info,
                    class_section:res.data.data.class_section,
                    facilities:res.data.data.facilities,
                    admission_info:res.data.data.admission_info,
                    toppers:res.data.data.toppers,
                    images:res.data.data.images
                })
            }
        })
    }
    myCallBack=e=>{
        console.log("refreshed")
        let data = new FormData()
        data.set("school_id",this.state.school_id)
        axios.post("/getSchoolProfileData",data)
        .then(res=>{
            if(res.status===200 && res.data.status===1){
                this.setState({
                    images:res.data.data.images
                })
            }
        })
        
    }
    callbackRemove=(id)=>{
        if(id && id!=="" && id!==undefined){
        let formData = new FormData()
        formData.set("school_id",this.state.school_id)
        formData.set("id",id)
        axios.post("/deleteToppers",formData)
        .then(res=>{
            if(res.status===200){
                if(res.data.status===1){
                    this.refreshData()
                }
                alert(res.data.message)
            }
        })
    }else{
        this.refreshData()
    }
    }
    refreshData(){
        let data = new FormData()
        data.set("school_id",this.state.school_id)
        axios.post("/getSchoolProfileData",data)
        .then(res=>{
            if(res.status===200 && res.data.status===1){
                this.setState({
                    toppers:res.data.data.toppers
                })
            }
        })
    }
    render() {
        console.log(this.state.school_id)
        return (
            <div>
            <MetaTags>
                    <title>FEEL | School Search Engine</title>
                    <meta name="description" content="Login page Feel, Feel Login, Feel Signup, Feel School Search engine, best school near me" />
                    <meta name="keywords" content="Feel Login, Feel educare Login, Feel Signup, schools near me, best schools near me , schools in Rajasthan, Schools in Jaipur" />
                    {/* <meta property="og:image" content="path/to/image.jpg" /> */}
            </MetaTags>
             <NavbarDefault />  
             <Container className="shadow p-2 mt-4 col-sm-6">
             <Tabs defaultActiveKey="schoolInfo">

                 <Tab eventKey="schoolInfo" title="School Information">
                     <SchoolInfo data={this.state.basic_info} />
                 </Tab>
                 <Tab eventKey="contactInfo" title="Contact Information">
                     <ContactInfo data={this.state.contact_info} ff={this.state.school_id}/>
                 </Tab>
                 <Tab eventKey="photos" title="Photos">
                     <PhotosInfo callback={this.myCallBack.bind(this)} data={this.state.images} ff={this.state.school_id} />
                 </Tab>
                 <Tab eventKey="classes" title="Classes">
                     <ClassInfo data={this.state.class_section} ff={this.state.school_id} />
                 </Tab>
                 <Tab eventKey="facilities" title="Facilities">
                     <FacilityInfo data={this.state.facilities} ff={this.state.school_id} />
                 </Tab>
                 <Tab eventKey="fees" title="Fees">
                     <FeesInfo data={this.state.class_section} ff={this.state.school_id} />
                 </Tab>
                 <Tab eventKey="result" title="Result">
                     <ResultInfo classes={this.state.class_section} data={this.state.toppers} ff={this.state.school_id} callback={this.callbackRemove.bind(this)} />
                 </Tab>
                 <Tab eventKey="admission" title="Admission">
                     <AdmissionInfo data={this.state.admission_info} ff={this.state.school_id} />
                 </Tab>
             </Tabs>
             </Container> 
             <Footer />
            </div>
        )
    }
}
