import React, { Component } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import Switch  from 'react-switch'
import axios from 'axios'

export default class AdmissionInfo extends Component {
    state = {
        checked:false,
        startDate:"",
        endDate:"",
        withTest:'0',
        startTime:"",
        endTime:"",
        test_type:'0',
        amount:"",
        test_time:"",
        test_date:"",
        school_id:this.props.ff || null
     }
    handleCheckChange=checked=>{
        this.setState({checked})
    }
    handleSubmit=e=>{
        e.preventDefault()
        let formdata = new FormData()
        formdata.set("school_id",this.state.school_id)
        formdata.set("admission_allow",this.state.checked?1:0)
        formdata.set("admission_start_date",this.state.startDate)
        formdata.set("admission_end_date",this.state.endDate)
        formdata.set("ad_test_datetime",this.state.test_date+" "+this.state.test_time)
        formdata.set("test_on_admission",this.state.withTest)
        formdata.set("admission_test_fees",this.state.test_type)
        formdata.set("admission_test_amount",this.state.amount)
        formdata.set("start_visiting_hrs",this.timeConvertor24to12(this.state.startTime))
        formdata.set("end_visiting_hrs",this.timeConvertor24to12(this.state.endTime))
        axios.post("/editSchoolAdmissionInfo",formdata)
        .then(res=>{
            if(res.status===200){
                if(res.data.status===1){
                    alert("updated successfully")
                }
            }
        }).catch(err=>{
            console.log(err.response)
        })
    }
    handleChange=e=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    componentWillReceiveProps(nextProps){
        if(this.props.data!==nextProps.data){
            let starttime=""
            let endtime=""
            if(nextProps.data.visiting_hours!=="" && this.checkStringForNumbers(nextProps.data.visiting_hours)){
                starttime = nextProps.data.visiting_hours.substring(0,8).replace(/ +/g, "")
                starttime = this.timeConvertor(starttime)
                endtime = nextProps.data.visiting_hours.substring(12).replace(/ +/g, "")
                endtime = this.timeConvertor(endtime)
            }
            this.setState({
            checked:nextProps.data.admission_allow===1,
            startDate:nextProps.data.admission_start_date,
            endDate:nextProps.data.admission_end_date,
            withTest:nextProps.data.test_on_admission===1?"1":"0",
            startTime: starttime,
            endTime:endtime,
            test_type:nextProps.data.admission_fees===0?'0':'1',
            amount:nextProps.data.admission_test_amount,
            test_date:nextProps.data.test_datetime.substring(0,10),
            test_time:nextProps.data.test_datetime.substring(11)

         })
        }
    }
     checkStringForNumbers(input){
        let str = String(input);
        for( let i = 0; i < str.length; i++){
            if(!isNaN(str.charAt(i))){     
                return false;
            }
        }
        return true
    }
     timeConvertor(time) {
         console.log(time)
        let PM = time.match('pm') ? true : false
        
        time = time.split(':')
        let min = time[1]
        let hour=null
        if (PM) {
         hour = 12 + parseInt(time[0],10)
            min = min.replace('pm','')
        } else {
         hour = time[0] 
            min = min.replace('am','')     
        }
        return hour+":"+min
    }
    timeConvertor24to12(time){
        time = time.split(':')
        let min  = time[1]
        let hour = time[0]
        let am_pm = ""
        if(hour>12){
            hour = hour-12;
            am_pm="pm"
        }else{
            am_pm="am"
        }
        return hour+":"+min+" "+am_pm
    }
    render() {
        console.log(this.state)
        return (
            <Form className="p-2" onSubmit={this.handleSubmit}>
            <Form.Group>
                <Row>
                    <Col>
                <h4>Admission Open</h4>
                </Col>
                <Col>
                <Switch className="float-right" onChange={this.handleCheckChange} checked={this.state.checked} />
                </Col>
                </Row>
            </Form.Group>
            {
                  this.state.checked?
                  <div>
                  <Form.Group>
                      <Form.Text>Start Date</Form.Text>
                  <Form.Control value={this.state.startDate} name="startDate" onChange={this.handleChange} type="date" />
                  <Form.Text>End Date</Form.Text>
                  <Form.Control value={this.state.endDate} name="endDate" onChange={this.handleChange} type="date" />
                  </Form.Group>
                  <Form.Group>
                 
                  <Form.Check checked={this.state.withTest==='1'} name="withTest" value="1" onChange={this.handleChange} inline type="radio" label="With Test"/>
                     <Form.Check checked={this.state.withTest==='0'} name="withTest" value="0" onChange={this.handleChange} inline type="radio" label="Without Test"/>
                  </Form.Group>
                  {this.state.withTest==='0'?
                  <Form.Group>
                      <Form.Label>Visit Duration</Form.Label>
                      <Form.Text>Start Time</Form.Text>
                      <Form.Control value={this.state.startTime} name="startTime" onChange={this.handleChange} type="time" />
                      <Form.Text>End Time</Form.Text>
                      <Form.Control value={this.state.endTime} name="endTime" onChange={this.handleChange} type="time" />
                  </Form.Group>:
                  <div>
                  <Form.Group>
                      <Form.Text>Test Type</Form.Text>
                      <Form.Check onChange={this.handleChange} name="test_type" checked={this.state.test_type==='1'} inline value="1" type="radio" label="Paid" />
                      <Form.Check onChange={this.handleChange} name="test_type" checked={this.state.test_type==='0'} inline value="0" type="radio" label="Free" />
                  </Form.Group>
                  {
                      this.state.test_type==='0'?
                      <div>
                      <Form.Group>
                          <Form.Control value={this.state.test_date} onChange={this.handleChange} name="test_date" type="date" />
                      </Form.Group>
                      <Form.Group>
                            <Form.Control value={this.state.test_time} onChange={this.handleChange}   name="test_time" type="time" />
                      </Form.Group>
                      </div>
                      :
                      <div>
                 <Form.Group>
                          <Form.Control value={this.state.test_date} onChange={this.handleChange} name="test_date" type="date" />
                      </Form.Group>
                      <Form.Group>
                            <Form.Control value={this.state.test_time} onChange={this.handleChange} name="test_time" type="time" />
                      </Form.Group>
                      <Form.Group>
                          <Form.Control onChange={this.handleChange} name="amount" value={this.state.amount} placeholder="Amount" type="number" />
                      </Form.Group>
                          </div>
                  }
                  </div>
}
                  </div>

                  :""
              }
            <Form.Group>
                  <Button type="submit" variant="secondary">Update</Button>
              </Form.Group>

        </Form>
        )
    }
}
