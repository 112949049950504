import React, {Component} from "react";
import { Card, InputGroup, FormControl, Button } from 'react-bootstrap'
import axios from 'axios'
import Form from 'react-bootstrap/Form';
import MetaTags from 'react-meta-tags'
import date from '../assets/images/svg/date.svg'
import email from '../assets/images/svg/email.svg'
import name from '../assets/images/svg/name.svg'
import location from '../assets/images/svg/location.svg'

export default class MyProfile extends Component {
    state = {
        name:"",
        dob:"",
        phone:0,
        email:"",
        profilePic:"",
        city:"",
        gender:"",
        user_id:0,
        editOnn:false
    }
    handleClick = e => {
        
        if(this.state.editOnn) {
            let bodydata = new FormData();
            // console.log(localStorage.getItem("parent_id"));
            bodydata.set("parent_id",localStorage.getItem("parent_id"));
            bodydata.set("full_name", this.state.name);
            bodydata.set("dob", this.state.dob);
            bodydata.set("email", this.state.email);
            bodydata.set("gender", this.state.gender);
            bodydata.set("city_name", this.state.city);
            bodydata.set("update", true);
            bodydata.set("user_id", this.state.user_id);
            console.log(this.state.name);
            axios.post("/updateMyProfile",bodydata)
            .then(res =>{
                console.log(res)
                if(res.status===200){
                    if(res.data.data.status===1){
                        
                    }else{
                        this.setState({error:true,loading:false})
                    }
                }else{
                    alert("network error")
                }
            }).catch(err =>{
                console.log(err.response);
            })
        }
        this.setState({editOnn: !this.state.editOnn})
         
    }
    componentDidMount() {
        this.getParentData();
    }
    handleChange = e =>{
        this.setState({[e.target.name] : e.target.value})
    }
    getParentData() {
        let formData = new FormData();
        formData.set("parent_id", localStorage.getItem("parent_id"));
        axios.post("/getMyProfile",formData)
        .then(res=>{
            console.log(res)
            if(res.status===200){
                if(res.data.data.status===1){
                    this.setState({
                        name:res.data.data.parentData.full_name,
                        dob:res.data.data.parentData.dob,
                        phone:"+91 " + res.data.data.parentData.phone,
                        profilePic:res.data.data.parentData.profile_pic!==""?res.data.data.parentData.profile_pic:"https://bootdey.com/img/Content/avatar/avatar1.png",
                        city:res.data.data.parentData.city_name,
                        email:res.data.data.parentData.email,
                        gender:res.data.data.parentData.gender,
                        user_id:res.data.data.parentData.user_id
                    })
                }
            }
        }).catch(err=>{
            console.log(err.response);
        })
    }

    render() {
        return (
            <div style={{marginTop:"48px"}}>
            <MetaTags>
                <title>{this.state.name + " | Feel"}</title>
                <meta name="description" content="India's First School Search Engine Platform, Get Admission in best school of your locality with FEEL" />
                <meta name="keywords" content="Schools, School search, school search engine, feeleducare, Feel Schools, Best school near me"/>
            </MetaTags>
                <Card>
                    <Card.Body>
                        <div className="account-settings">
                            <div className="user-profile" style={{textAlign:"center"}}>
                                <div className="user-avatar" >
                                    <img src={this.state.profilePic} alt="My Profile img" style={{height:"120px", width:"120px", borderRadius:"150px"}} />
                                </div>
                                <h5 className="user-name" style={{marginTop:"10px"}}>{this.state.name}</h5>
                                <h6 className="user-email" style={{marginTop:"10px", marginBottom:"20px"}}>{this.state.phone}</h6>
                            </div>
                            <InputGroup style={{marginBottom:"10px"}}>
                                <InputGroup.Prepend>
                                    <InputGroup.Text  id="basic-addon1"><img alt="name" src={name} width={20} height={20} /></InputGroup.Text>
                                </InputGroup.Prepend>
                                    <FormControl type="text" name="name" value={this.state.name} onChange={this.handleChange} placeholder="Full Name" aria-label="Full Name" disabled={!this.state.editOnn} readonly={!this.state.editOnn} />
                            </InputGroup>
                            <InputGroup style={{marginBottom:"10px"}}>
                                <InputGroup.Prepend>
                                    <InputGroup.Text  id="basic-addon1"><img alt="email" src={email} width={20} height={20}/></InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="email" name="email" value={this.state.email} onChange={this.handleChange} placeholder="Email" aria-label="Email" disabled={!this.state.editOnn} readonly={!this.state.editOnn} />
                            </InputGroup>
                            <InputGroup style={{marginBottom:"10px"}}>
                                <InputGroup.Prepend>
                                    <InputGroup.Text  id="basic-addon1"><img alt="location" src={location} width={20} height={20}/></InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="text" name="city" value={this.state.city} onChange={this.handleChange} placeholder="City" aria-label="City" disabled={!this.state.editOnn} readonly={!this.state.editOnn} />
                            </InputGroup>
                            <InputGroup style={{marginBottom:"10px"}}>
                                <InputGroup.Prepend>
                                    <InputGroup.Text  id="basic-addon1"><img alt="date" src={date} width={20} height={20}/></InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="date" name="dob" value={this.state.dob} onChange={this.handleChange} aria-label="Date of Birth" disabled={!this.state.editOnn} readonly={!this.state.editOnn} />
                            </InputGroup>
                            <InputGroup>
                                <Form.Check inline label="Male" name="gender" value="male" type="radio" id='inline-radio-1' onChange={this.handleChange} style={{display:this.state.editOnn?"block":"none"}}/>
                                <Form.Check inline label="Female" name="gender" value="female" type="radio" id='inline-radio-2' onChange={this.handleChange} style={{display:this.state.editOnn?"block":"none"}} />
                            </InputGroup>
                            <Button onClick={this.handleClick} className="my-3" variant="secondary">{this.state.editOnn?"Done":"Edit"}</Button>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}