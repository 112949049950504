import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Maindiv } from '../Styled/Hero'
import {FullContainer} from '../Styled/Login'
import NavbarDefault from '../Components/Navbar/NavbarDefault'
import Footer from '../Components/Footer'
import { Image } from '../Styled/Section'
import backto from '../assets/images/Back to school-pana.svg'
import SchoolLogin from '../Components/SchoolLogin'
import VerifySchool from '../Components/VerifySchool'
import {withRouter} from 'react-router-dom'
import MetaTags from 'react-meta-tags'

class schoolLogin extends Component {
    state = {
        screen:0,
        user_id:null,
        user_phone:null
    }
    mycallback = (screen,user_id,phone) =>{
        this.setState({screen:screen,user_id:user_id,user_phone:phone})
    }
    render() {
        let content= "";
        if(this.state.screen===0){
            content = <SchoolLogin data={{callback:this.mycallback.bind(this)}} />
        }else if(this.state.screen===1){
            content = <VerifySchool data={{screen:this.mycallback.bind(this),user_id:this.state.user_id,phone:this.state.user_phone}} />
        }else if(this.state.screen===2){
            this.props.history.push({
                pathname:"/registration",
                data:this.state.user_id
            })
        }
        return (
            <Maindiv>
            <MetaTags>
                <title>Login School | Feel</title>
                <meta name="description" content="Login page Feel, Feel Login, Feel Signup, Feel School Search engine, best school near me" />
                <meta name="keywords" content="Feel Login, Feel educare Login, Feel Signup, schools near me, best schools near me , schools in Rajasthan, Schools in Jaipur" />
                {/* <meta property="og:image" content="path/to/image.jpg" /> */}
            </MetaTags>
            <NavbarDefault/>
            <FullContainer className="border" fluid>
                <Row className="my-auto">
                 <h1>Boost Your School Admissions with FEEL</h1>
                 </Row>
                 <Row className="my-auto" style={{width:"100%"}}>
                     <Col xs={12} md={7} className="text-center">
            <Image alt="feeleducare-back-img" src={backto} height={500} heightSm={300} heightMd={400}/>
                </Col>
                <Col xs={12} md={5} className="my-auto">
                    {content}
                </Col>
                </Row>
        
            </FullContainer>
            <Footer />
        </Maindiv>
        )
    }
}

export default withRouter(schoolLogin)