import React, { Component } from 'react'
import NavbarDefault from '../Components/Navbar/NavbarDefault'
import { Form, Container, Button } from 'react-bootstrap'
import MetaTags from 'react-meta-tags'

export default class upload extends Component {
    render() {
        return (
            <div>
                <MetaTags>
                    <title>FEEL | School Search Engine</title>
                    <meta name="description" content="Login page Feel, Feel Login, Feel Signup, Feel School Search engine, best school near me" />
                    <meta name="keywords" content="Feel Login, Feel educare Login, Feel Signup, schools near me, best schools near me , schools in Rajasthan, Schools in Jaipur" />
                    {/* <meta property="og:image" content="path/to/image.jpg" /> */}
                </MetaTags>
                <NavbarDefault />
                <Container className="shadow mt-4 col-sm-6">
                <Form className="p-2">
                    <Form.Group>
                        <Form.Control type="text" placeholder="title"/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control type="text" placeholder="description"/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control type="text" placeholder="tags"/>
                    </Form.Group>
                    <Button>upload</Button>
                </Form>
                </Container>
            </div>
        )
    }
}
