import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { NotFound } from '../Styled/NotFound'
import MetaTags from 'react-meta-tags'

export default class notFound extends Component {
    render() {
        return (
            <NotFound>
            <MetaTags>
                <title>Feel | Page not Found</title>
            </MetaTags>
            <h2><FontAwesomeIcon icon={faExclamationTriangle}/> 404</h2>
           <h4>Sorry, we couldn't find this page</h4>
           </NotFound> 
        )
    }
}
