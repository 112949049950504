import React, { Component } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import {Text,StyledFooter, CustomizedFa} from '../Styled/Footer'
import { faFacebookSquare ,faInstagramSquare,faTwitterSquare,faLinkedin  } from '@fortawesome/free-brands-svg-icons'
export default class Footer extends Component {

    render() {
        return (
            <StyledFooter bottom={this.props.bottom}>
                <Navbar bg="white" className="pb-0">
                    <Nav className="mx-auto">
                    <a href="https://facebook.com/feeleducareofficial/"><CustomizedFa className="mr-3" size="2x" icon={faFacebookSquare } /></a>
                    <a href="https://twitter.com/Feeleducare"><CustomizedFa className="mr-3" size="2x" icon={faTwitterSquare } /></a>
                    <a href="https://instagram.com/feeleducare/"><CustomizedFa className="mr-3" size="2x" icon={faInstagramSquare } /></a>
                    <a href="https://linkedin.com/company/feeleducare"><CustomizedFa size="2x"icon={faLinkedin } /></a>
        
                    </Nav>
                </Navbar>
                <Navbar bg="white" className="">
                    <Nav className="mx-auto">
                        <Text className="text-dark py-0">FEEL &copy; 2022</Text>
                        <Text href="https://feeleducare.com/about-school-ERP/feel-privacy-notice.php" className="text-dark py-0">Privacy Policy</Text>
                        <Text href="https://feeleducare.com/about-school-ERP/contact.php" target="__blank" className="text-dark py-0">Contact Us</Text>
                    </Nav>
                </Navbar>
            </StyledFooter>
        )
    }
}
