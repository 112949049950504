import React, { Component } from 'react'
import image from '../assets/images/dream.png'
import { Row, Col, Container } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import {FullContainer,WelcomeLabel, Image,Maindiv} from '../Styled/Section'
import './index.css'
export default class SectionSecond extends Component {
    render() {
        // return (
        //     <div style={{position:"relative", marginTop:"4vh"}}>
        //         <img id="back-img" alt="Feel-online-school-search-engine-in-india" src={image}/>
                
        //         <div id="textDiv" >

        //         We believe that education is the key to our future, and we want to make sure that you have access to the best schools near me. If you are looking for RBSE schools near me or CBSE schools near me, then you have come to the right place. We have made it our mission to help students find their dream school. 
        //         </div>
        //     </div>
                
        // )
        return (
            <div style={{marginBottom : "40px"}}>
                <FullContainer fluid className="" style={{display:"block"}}>
                    <Row style={{width:"100%"}} className="text-center my-auto">
                        <Col sm={12} md={6}  style={{marginBottom:"20px"}}>
                            <Image style={{display:"inline-block"}} src={image} width={500} widthSm={300} widthMd={350} height={374} heightSm={225} heightMd={262} alt="Feel School Search Engine in India" />   
                        </Col>
                        <Col sm={12} md={6}>
                            <h2>Best Schools, RBSE and CBSE schools near you</h2>
                            <p>We believe that education is the key to our future, and we want to make sure that you have access to the best schools near me. If you are looking for RBSE schools near me or CBSE schools near me, then you have come to the right place. We have made it our mission to help students find their dream school. </p>
                            
                        </Col>
                    </Row>
                </FullContainer>
            </div>
        )
    }
}
