import React, { Component } from "react";
import {
  SuggestionList,
  FormSearch,
  InnerForm,
  InputField,
  InputContainer,
  Suggestions,
  InputSelect,
  ChoicesSelect,
  ChoicesOptions,
  StartButton,
  ListPara,
} from "../Styled/Hero";
import { Image } from "../Styled/Section";
import axios from "axios";
import marker from "../assets/images/marker.png";
import { withRouter } from "react-router-dom";
import cityList from "../assets/citys.json";
class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      schools: [],
      classes: [],
      mediums: [],
      showAlert: false,
      selectedClass: {
        id: null,
        name: "",
      },
      selectedMedium: {
        id: null,
        name: "",
      },
      isClicked: true,
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleOutsideClick.bind(this);
  }
  componentDidMount() {
    window.addEventListener("mousedown", this.handleOutsideClick, false);
    axios
      .get("/getCommonData")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.status === 1) {
            this.setState({
              classes: res.data.data.classes,
              mediums: res.data.data.medium,
            });
          }
        }
      })
      .catch((err) => {});
  }
  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleOutsideClick, false);
  }
  handleOutsideClick = (ev) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(ev.target)) {
      this.setState({ schools: null, isClicked: true });
    }
  };
  handleChange = (ev) => {
    this.setState({
      query: ev.target.value,
      isClicked: false,
    });
    this.requestSuggestions(ev.target.value);
  };
  handleSelectChange = (ev) => {
    this.setState({
      [ev.target.name]: {
        id: ev.target.options.selectedIndex,
        name: ev.target.value,
      },
    });
  };
  handlListClick = (ev) => {
    if (ev.target.querySelector("p")) {
      this.setState({ query: ev.target.querySelector("p").innerHTML });
    } else {
      this.setState({
        query: ev.target.innerHTML,
      });
    }
    this.setState({ schools: null, isClicked: true });
  };
  handleFormSubmit = (ev) => {
    ev.preventDefault();

    if (
      this.state.query === "" ||
      this.state.selectedClass.name === "" ||
      this.state.selectedMedium.name === "" ||
      this.state.selectedClass.name === "Class" ||
      this.state.selectedMedium.name === "Medium"
    ) {
      this.setState({ showAlert: true });
      return;
    }

    this.props.history.push({
      pathname: "/search",
      search:
        "?query=" +
        this.state.query +
        "&class=" +
        this.state.selectedClass.name +
        "&medium=" +
        this.state.selectedMedium.name,
      state: {
        selectedClass: this.state.selectedClass,
        selectedMedium: this.state.selectedMedium,
      },
    });
  };
  requestSuggestions(query) {
    if (this.state.query !== "") {
      let bodyFormData = new FormData();
      bodyFormData.set("keyword", query);
      axios
        .post("/getSchoolListingPaginate", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.data.status === 1 && res.data.data != null) {
              //let locArr = []
              this.setState({ schools: res.data.data.school_data });
              // this.state.schools.forEach(element => {
              //   locArr.push(element.city_name)
              // });
              // const uniq = new Set(locArr)
              // locArr = [...uniq]
              // this.setState({
              //   locations:locArr
              // })
            } else {
              this.setState({ schools: null, isClicked: false });
            }
          }
        })
        .catch((err) => {
          if (!err.response) {
            console.log(err);
          }
        });
    } else {
      this.setState({ schools: null, isClicked: false });
    }
  }
  render() {
    let newarr = [];
    if (cityList && cityList.length > 0) {
      cityList.map((location, index) =>
        location.name.toLowerCase().includes(this.state.query.toLowerCase())
          ? newarr.push({ name: location.name, img: marker })
          : ""
      );
    }
    if (
      this.state.schools &&
      this.state.query &&
      this.state.schools.length > 0
    ) {
      this.state.schools.map((school, index) =>
        newarr.push({ name: school.sch_name, img: school.sch_banner })
      );
    }
    return (
      <React.Fragment>
        <FormSearch ref={this.wrapperRef} onSubmit={this.handleFormSubmit}>
          <InnerForm className="border">
            <InputContainer firstWrap>
              <InputField
                value={this.state.query}
                onChange={this.handleChange}
                placeholder="City, School?"
                autoComplete="none"
              />
              <Suggestions
                className="border"
                show={
                  !this.state.isClicked &&
                  newarr &&
                  newarr.length > 0 &&
                  this.state.query !== ""
                }
              >
                <ul>
                  {newarr.map((arr, index) => (
                    <SuggestionList onClick={this.handlListClick} key={index}>
                      <Image
                        className="mr-2"
                        width={30}
                        height={30}
                        src={arr.img}
                        alt={arr.name + "-img"}
                      />
                      <ListPara>{arr.name}</ListPara>
                    </SuggestionList>
                  ))}
                </ul>
              </Suggestions>
            </InputContainer>
            <InputContainer secondWrap>
              <InputSelect>
                <ChoicesSelect
                  name="selectedClass"
                  value={this.state.selectedClass.name}
                  onChange={this.handleSelectChange}
                >
                  <ChoicesOptions>Class</ChoicesOptions>
                  {this.state.classes && this.state.classes.length > 0
                    ? this.state.classes.map((clas, index) => (
                        <ChoicesOptions key={clas.id}>
                          {clas.name}
                        </ChoicesOptions>
                      ))
                    : ""}
                </ChoicesSelect>
              </InputSelect>
            </InputContainer>
            <InputContainer thirdWrap>
              <InputSelect>
                <ChoicesSelect
                  name="selectedMedium"
                  onChange={this.handleSelectChange}
                  value={this.state.selectedMedium.name}
                >
                  <ChoicesOptions>Medium</ChoicesOptions>
                  {this.state.mediums && this.state.mediums.length > 0
                    ? this.state.mediums.map((medium, index) => (
                        <ChoicesOptions key={medium.id}>
                          {medium.name}
                        </ChoicesOptions>
                      ))
                    : ""}
                </ChoicesSelect>
              </InputSelect>
            </InputContainer>
          </InnerForm>
          <StartButton
            type="submit"
            className="mt-4 text-secondary"
            variant="light"
            style={{ fontSize: "1.1rem" }}
          >
            Start with{" "}
            <img
              alt={"feel-logo-img"}
              height={20}
              width={"40%"}
              src={require("../assets/images/feel_logo.webp")}
            />
          </StartButton>
        </FormSearch>
      </React.Fragment>
    );
  }
}
export default withRouter(SearchBar);
