import React, { Component } from 'react'
import { MainDiv, StyledButton } from '../Styled/SchoolProfile'
import NavbarSearch from '../Components/Navbar/NavbarSearch'
import { Navbar, Container, Jumbotron, Row, Col, Card } from 'react-bootstrap'
import phone from '../assets/images/phone.svg'
import company from '../assets/images/company.svg'
import star from '../assets/images/star.svg'
import SchoolHero from '../Components/SchoolHero'
import axios from 'axios'
import Footer from '../Components/Footer'
import meeting from '../assets/images/Meeting.gif'
import { Image } from '../Styled/Section'
import winner from '../assets/images/winners.webp'
import credit from '../assets/images/credit.svg'
import  '../Styled/style.css'
import {instance} from '../utils/instance'
import MetaTags from 'react-meta-tags'

export default class schoolProfile extends Component {
    state = {
        schoolData:[],
        classes:[],
        images:[],
        toppersData:[],
        schoolOtherData:[],
        adData:[],
        feesData:{
            quarterly_fees:0,
            half_yearly_fees:0,
            annualy_fees:0
        },
        id:0,
        selectedClass: "",
        selectedMedium:"",
        admissionCount:0
    }
    componentDidMount(){
        this.setState({
            selectedClass:this.props.location.state? this.props.location.state.class_id.toString():"",
            selectedMedium:this.props.location.state?this.props.location.state.medium_id.toString():""
        });
        let str = this.props.match.params.IdAndSchoolName
        const arr = str.split('-');
        const id = arr[0];
        this.setState({id: id});
        this.getSchool(id, parseInt(this.state.selectedClass))
        this.getFees(id, this.state.selectedClass)
        this.getAdmissions(id)
    }
    getAdmissions(school_id){
        let formdata = new FormData()
        formdata.set("school_id",school_id)
        axios.post('/getAdmissionReqListing',formdata)
        .then(res=>{
            if(res.status===200){
                if(res.data.status===1){
                    if(res.data.data && res.data.data.upcoming){
                        this.setState({
                            admissionCount:res.data.data.upcoming.length
                        })
                    }
                }
            }
        })
    }
    getFees(school_id,class_id){

        let data  = new FormData()
        if(school_id && class_id){
            data.set("school_id",school_id)
            instance.post("/getfees.php",data)
            .then(res=>{
                if(res.data.status===1){
                    let ls = JSON.parse(res.data.data)
                    ls.forEach(element => {
                        if(parseInt(element.class_id)===parseInt(class_id)){
                            this.setState({
                                feesData:{annualy_fees:element.fees,half_yearly_fees:element.fees/2,quarterly_fees:element.fees/4}
                            })
                        }
                    }); 
                }
            })
        }

    }
    getSchool(school_id,class_id){
        let data = new FormData()
        if(school_id){
            data.set("school_id",school_id);
            if(class_id){
                data.set("class_id",class_id)
            }
            axios.post("/getSchoolProfile",data)
            .then(res=>{
               if(res.status===200){
                   if(res.data.data.status===1){
                      
                       this.setState({
                         schoolData:res.data.data.schoolData,
                         classes:res.data.data.classes,
                         images:res.data.data.images,
                         toppersData:res.data.data.toppersData,
                         schoolOtherData:res.data.data.schoolOtherData,
                         adData:res.data.data.adData,
                          
                       })
    
                   }
               }
            })
            
        }else{
            alert("something went wrong")
        }

    }
    handleFees = e =>{
        this.setState({selectedClass:e.target.value})
        let str = this.props.match.params.IdAndSchoolName
        const arr = str.split('-');
        const id = arr[0];
        console.log("ID fro school is ...", id);
        this.getFees(id,e.target.value)
    }
    handleMed=e=>{
        this.setState({
            selectedMedium:e.target.value
        })
    }
    render() {
        console.log(this.state.feesData)
        return (
            <React.Fragment>
                <MetaTags>
                    <title>{this.state.schoolData.sch_name + " | Feel"}</title>
                    <meta name="description" content="Login page Feel, Feel Login, Feel Signup, Feel School Search engine, best school near me" />
                    <meta name="keywords" content="Feel Login, Feel educare Login, Feel Signup, schools near me, best schools near me , schools in Rajasthan, Schools in Jaipur" />
                    {/* <meta property="og:image" content="path/to/image.jpg" /> */}
                </MetaTags>
                <MainDiv>
                <NavbarSearch  />
                <SchoolHero images={this.state.images} data={this.state.schoolData} count={this.state.admissionCount} />
                </MainDiv>
                <Jumbotron className="p-4">
                    <Container>
                        <Row>
                        <Col>
                    <Row>
        <h6>{this.state.schoolData.sch_name}</h6>
        <span className="ml-2">{this.state.schoolData.total_rating}</span>
        <img alt="" style={{verticalAlign:"middle"}} className="ml-1" width={20} src={star}/>

        </Row>
        <Row>
        <p>{this.state.schoolData.board+", "}{this.state.schoolData.medium_ids==='1'?"Hindi Medium":this.state.schoolData.medium_ids==="2"?"English Medium":"Hindi and English Medium"}{" School"}</p>

        </Row>
        <Row>
            <a className="res-map mr-2" target="__blank" href={"http://www.google.com/maps/place/"+this.state.schoolOtherData.latitude+","+this.state.schoolOtherData.longitude}><img style={{verticalAlign:"middle"}} width={40} src={company} alt="map" /></a>
        <p>{this.state.schoolData.street_name}, {this.state.schoolData.city_name}, {this.state.schoolData.state_name}</p>
        </Row>
        </Col>
        <Col className="my-auto">
            <Row>
                <Col className="mb-2">
        <a href={"tel:"+this.state.schoolOtherData.phone}><img className="float-right" width={40} src={phone} alt="phone" /></a>
        </Col>
        </Row>
       
        <Row>
        <Col className="mt-2" >
        <a className="res-map2" style={{float:"right"}} target="__blank" href={"http://www.google.com/maps/place/"+this.state.schoolOtherData.latitude+","+this.state.schoolOtherData.longitude}><img style={{verticalAlign:"middle"}} width={40} src={company} alt="map" /></a>
        </Col>
        </Row>
        </Col>
        </Row>
        </Container>
                </Jumbotron>
                <Container fluid>
                    <Row>
                        <Col className="text-center resp" sm={12} md={6}>
                        <Image width={450} widthSm={350} widthMd={400} src={meeting} alt="feeleducare-feel-meeting-img"/>
                        </Col>
                        <Col md={6} sm={12} className="text-center my-auto">
                        <h1>Description</h1>
        <p>{this.state.schoolData.about_school}</p>
                        </Col>
                    </Row>
                </Container>
                <Container style={this.state.toppersData && this.state.toppersData.length>0?{display:"block"}:{display:"none"}} fluid>
                    <h1 className="text-center">Our Toppers</h1>
<Row>
    <Col sm={12} md={7}>
    {this.state.toppersData && this.state.toppersData.length>0? this.state.toppersData.map((topper,index)=>(
       <div key={index}>
        <p key={index}>{topper.title}</p>
        <Container>
        <Row>
           
        {topper.result.map((sub,index)=>(
                    <Card  key={index} className="shadow p-2 m-2 resp-card">
                            <Card.Img src={sub.stud_img} className="rounded-circle"/>
                            <Card.Body className="p-0 text-center">
                <Card.Text className="mb-0">{sub.stud_name}</Card.Text>
        <Card.Text className="mb-0">{sub.marks}%</Card.Text>
            </Card.Body>
                            </Card>
        ))
        }
     
        </Row>
        </Container>
        </div>
    )):""}
    </Col>
    <Col className="text-center resp" sm={12} md={5}>
    <Image src={winner} alt="feel-educaree-winner-img" width={400} widthSm={250} widthMd={300} />
    </Col>
</Row>
                </Container>
                <Container fluid>
                    <h1 className="text-center">Fees</h1>
                    <Row className="justify-content-center">
                        <Col className="text-center resp" sm={12} md={7}>
                        <Image alt="feel-educaree-credit-img" src={credit} width={400} widthSm={350} />
                        </Col>
                        <Col sm={12} md={5}>
                        <Row>
                            <Col>
                        <select name="classes" value={this.state.selectedClass} onChange={this.handleFees} style={{width:"100%",borderRadius:"20px"}} className="shadow form-control">
                            <option>Select class</option>
                            {this.state.classes?
                                this.state.classes.map((clas,index)=>(
                                <option value={clas.id} key={clas.id}>{clas.name}</option>
                                )):""
                            }
                        </select>
                        </Col>
                        <Col>
                        <select onChange={this.handleMed} value={this.state.selectedMedium} style={{width:"100%",borderRadius:"20px"}} className="shadow form-control">
                            <option key="0">Select Medium</option>
                            <option value="1" key="1">Hindi</option>
                            <option value="2" key="2">English</option>
                        </select>
                        </Col>
                        </Row>
                        <Row>
                            <Card style={{width:"90%"}} className="shadow p-4 mx-auto mt-4">
                                <Card.Body>
                                  {/*  <Row>
                                    <Col>
        <p style={{fontSize:"14px"}}>Form Fees</p>
        <p style={{fontSize:"14px"}}>Admission Fees</p>
        <p style={{fontSize:"14px"}}>Tution Fee</p>
        <p style={{fontSize:"14px"}}>Total</p>
        </Col>
        <Col>
       
        <p style={{fontSize:"14px"}}>{this.state.feesData.form_fees}</p>
                        <p style={{fontSize:"14px"}}>{this.state.feesData.admission_fees}</p>
                        <p style={{fontSize:"14px"}}>{this.state.feesData.tution_fees}</p>
                        <p style={{fontSize:"14px"}}>{this.state.feesData.admission_fees+this.state.feesData.form_fees+this.state.feesData.tution_fees}</p>

    
        </Col>
                                  </Row>*/}

                                    <Row>
                                    <Col>
        <p style={{fontSize:"14px"}}>Quaterly</p>
        <p style={{fontSize:"14px"}}>Half Yearly</p>
        <p style={{fontSize:"14px"}}>Anually</p>
        </Col>
        <Col>
                        <p style={{fontSize:"14px"}}>{this.state.feesData.quarterly_fees}</p>
                        <p style={{fontSize:"14px"}}>{this.state.feesData.half_yearly_fees}</p>
                        <p style={{fontSize:"14px"}}>{this.state.feesData.annualy_fees}</p>
        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col>
                                        <p style={{fontSize:"14px"}}>Total</p>
                                        </Col>
                                        <Col>
                                <p style={{fontSize:"14px"}}>{this.state.feesData.annualy_fees}</p>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Container className="mt-2">
                            <hr/>
                        <h4>FEEL's Admission Policy</h4>
                        
                            <li><p style={{display:"inline"}}>Cancellation Policy.</p></li>
                            <li><p style={{display:"inline"}}>Please carry child birth certificate at the time of admission.</p></li>
                            <li><p style={{display:"inline"}}>Guardian also carry a legal ID proof at the time of admission.</p></li>
                        
                        </Container>
                    </Row>
                
                
                </Container>
                <Footer />
                <div style={{height:"10vh"}}></div>
              
                <Navbar fixed="bottom" className="bg-light">
                <StyledButton href={localStorage.getItem('isLogged')==='true' && localStorage.getItem('type')==='school'?'/editprofile':"/admission/"+this.state.id} target="__blank" disabled={localStorage.getItem('isLogged')==='true' && localStorage.getItem('type')==='school'?false: this.state.adData.admission_allow===0}  variant="secondary" className="mx-auto col-sm-3">{localStorage.getItem('isLogged')==='true' && localStorage.getItem('type')==='school'?'Edit Profile':this.state.adData.admission_allow===1?"Admission":"Admission Closed"}</StyledButton>
                </Navbar>
                </React.Fragment>
        )
    }
}
