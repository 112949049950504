import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import Geocode from "react-geocode";
import axios from 'axios';
import Map from '../Map';
Geocode.setApiKey("AIzaSyB_PBN7fBrfdPUOg_JE1LB7CkQQuAVNwHQ");
Geocode.enableDebug();
export default class ContactInfo extends Component {
    state = {
        latitude:28.7041,
        longitude:77.1025,
        states:[],
        cities:[],
        state_id:"",
        city_id:"",
        sch_address:"",
        pincode:"",
        contact_phone:"",
        email:"",
        error:false  
    }
    componentDidMount(){
        this.position()
        axios.post("/getStates")
        .then(res=>{
            console.log(res)
            if(res.status===200 && res.data.status===1){
                this.setState({states:res.data.data})
            }
        })

    }
    position = async () => {
        await navigator.geolocation.getCurrentPosition(
          position => {this.setState({ 
            latitude: position.coords.latitude, 
            longitude: position.coords.longitude
          })
          Geocode.fromLatLng(position.coords.latitude,position.coords.longitude)
          .then(
              res=>{
                  console.log(res)
                  this.setState({
                      sch_address:res.results[0].formatted_address
                  })
              }
          )
        
        }, 
          err => console.log(err)
        );

      }

      handleChange=e=>{
          this.setState({[e.target.name]:e.target.value})
      }
      handleSelectChange = e=>{
          this.setState({[e.target.name]:e.target.value},
            )   
            if(e.target.name==="state_id")
            this.getCities(e.target.value)   
            console.log(e.target.options.selectedIndex+" "+e.target.value)  

      }

      handleSubmit=e=>{
          e.preventDefault()
          console.log(this.state)
          if(this.state.email!=="" && this.state.contact_phone.length===10 && this.state.pincode.length===6 && this.state.state_id!=="" && this.state.city_id!=="" && this.state.sch_address!==""){
              let arr = {
                  email:this.state.email,
                  contact_phone:this.state.contact_phone,
                  pincode:this.state.pincode,
                  state_id:this.state.state_id,
                  city_id:this.state.city_id,
                  sch_address:this.state.sch_address,
                  latitude:this.state.latitude,
                  longitude:this.state.longitude
              }
              this.props.data.screen(2,arr)
          }else{
              this.setState({error:true})
          }
      }
      getCities(state_id){
            let data = new FormData()
            data.set("state_id",state_id)
            axios.post("/getCities",data)
            .then(res=>{
                console.log(res)
                if(res.status===200 && res.data.status===1){
                    this.setState({cities:res.data.data})
                }
            })

      }
    render() {
        return (
            <div>
                <h1 className="text-center">Contact Information</h1>
                <Form className="p-2" onSubmit={this.handleSubmit}>
                    <Form.Group>
                        <Form.Control onChange={this.handleChange} value={this.state.sch_address}  name="sch_address"  placeholder="Address" type="text" required/>
                    </Form.Group>
                    <Form.Group>
 <Map latitude={this.state.latitude} longitude={this.state.longitude} />
  

                    </Form.Group>
                    <Form.Group>
                        <Form.Control value={this.state.state_id} name="state_id" as="select" onChange={this.handleSelectChange}>
                            <option>Select State</option>
                            {this.state.states?this.state.states.map((st,index)=>(
                                <option value={st.state_id} key={index}>{st.state_name}</option>
                            )):""}
                        </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control value={this.state.city_id} name="city_id" onChange={this.handleSelectChange} as="select">
                                <option>Select City</option>
                                {this.state.cities?this.state.cities.map((city,index)=>(
                                    <option value={city.city_id} key={index}>{city.city_name}</option>
                                )):""}
                            </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control name="pincode" onChange={this.handleChange} value={this.state.pincode} type="tel" maxLength="6"placeholder="Pincode" required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control name="email" onChange={this.handleChange} value={this.state.email} type="email" placeholder="Email" required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control onChange={this.handleChange} name="contact_phone" value={this.state.contact_phone} type="tel" maxLength="10" placeholder="Contact Number" required/>
                    </Form.Group>
                    <Form.Group>
        <span className="text-danger">{this.state.error?"Invalid field found":""}</span>
            </Form.Group>
                    <Form.Group>
                        <Button type="submit" variant="secondary">Next</Button>
                    </Form.Group>
                </Form>
            </div>
        )
    }
}
