import React, { Component } from 'react'
import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSync } from '@fortawesome/free-solid-svg-icons'


export default class AdmissionStatusParent extends Component {
    render() {
        return (
           
            <div>
               {this.props.data && this.props.data.length>0?this.props.data.map((list,index)=>(
                    list.general_data.ad_status===this.props.tabName?
                    
                    <Card key={index}>
                        <Card.Body>
                            <img alt="" width={100} src={list.general_data.sch_banner}/>
                            <h5 className="ml-2" style={{display:"inline"}}>{list.general_data.sch_name}</h5>
                            <p>Student Name: {list.general_data.student_name}</p>
                            <p>Status: {list.general_data.ad_status}</p>
                            <p>Admission ID: {list.general_data.unique_id}</p>
                            <p>Visiting Time: {list.general_data.visiting_hours}</p>
                        </Card.Body>
                    </Card>:<h4 style={{textAlign:"center"}}className="mx-auto">Synchronizing &nbsp;<FontAwesomeIcon  icon={faSync} spin /></h4>

               ))
  
            :<p>No data found</p>}
            </div>
        )
    }
}
