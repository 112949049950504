import styled from 'styled-components'
import { Container } from 'react-bootstrap';

export const FullContainer = styled(Container)`
    height: 100%;
    width:100%;
    margin:0;
    display:flex;
    justify-content:center;
`

export const WelcomeLabel = styled.label`
    font-weight:600;
    font-size:3.2rem;
`
export const Image = styled.img`
    width:${(props)=>props.width}px;
    height:${(props)=>props.height}px;

    @media(max-width:998px){
        width:${(props)=>props.widthMd}px;
        height:${(props)=>props.heightMd}px;
    }
    @media(max-width:768px){
        width:${(props)=>props.widthSm}px;
        height:${(props)=>props.heightSm}px;
    }
`
export const Maindiv = styled.div`
    height:100vh;
    width:100%;
    @media(max-width:998px){
        height:80vh;
    }
`