import React, { Component } from 'react'
import { Card, Button } from 'react-bootstrap';
import OTPInput, { ResendOTP } from "otp-input-react";
import axios from 'axios'
import {withRouter} from 'react-router-dom'
class VerifySchool extends Component {
    state = {
        otp:"",
        error:false,
        loading:false,
        phone:this.props.data.phone,
        user_id:this.props.data.user_id
    }
    handleChange = val =>{
        this.setState({otp:val})
    }
    handleResend = e  =>{
        this.setState({loading:true})
        let data = new FormData();
        data.set("user_phone",this.state.phone)
        axios.post("/resendOtp",data)
        .then(res=>{
            console.log(res);
        }).catch(err=>{
            console.log(err);
        })
        this.setState({loading:false})
    }
    handleClick = () => {
        if(this.state.otp!=="" && this.state.otp.length===4 && this.state.user_id){
            this.setState({error:false,loading:true})
            let bodydata = new FormData();
            bodydata.set("user_id",this.state.user_id);
            bodydata.set("user_type","S");
            bodydata.set("otp",this.state.otp)
            axios.post("/verifyPhone",bodydata)
            .then(res =>{
                console.log(res)
                if(res.status===200){
                    if(res.data.data.status===1){
                        if(res.data.data.home_data!==null && res.data.data.home_data.length!==0){
                            localStorage.setItem("isLogged",true)
                            localStorage.setItem("type","school")
                            localStorage.setItem("school_id",res.data.data.home_data.school_id);
                            localStorage.setItem("school_phone",res.data.data.home_data.contact_phone)
                            this.props.history.push({pathname:"/schoolProfile/"+res.data.data.home_data.school_id})
                        }else{
                            this.props.data.screen(2,this.state.user_id,this.state.phone)
                        }
                        
                    }else{
                        this.setState({error:true,loading:false})
                    }
                }else{
                    alert("network error")
                }
            }).catch(err =>{
                console.log(err);
            })
            this.setState({loading:false});

        }else if(!this.state.user_id){
            this.setState({error:true})
            alert("something went wrong please try again")
        }
        else{
           this.setState({error:true})
        }
    }
    render() {
        const renderButton = buttonProps => {
            return <button className="btn btn-outline-dark" {...buttonProps}>Resend</button>;
          };
        return (
            <Card className="p-3 shadow">
            <Card.Title>
                <h1>Verify Phone</h1>
            </Card.Title>
            <Card.Body>
                <Card.Text>
                    Please Enter OTP to continue
                    </Card.Text>
                    <OTPInput autoFocus OTPLength={4} otpType="number" onChange={this.handleChange} value={this.state.otp} />
                    <ResendOTP renderButton={renderButton} onResendClick={this.handleResend} className="mt-2" maxTime={30} disabled={false}/>
<Button disabled={this.state.loading} onClick={this.handleClick} className="my-3" variant="secondary">Login</Button>
<span className="float-right text-danger">{this.state.error?"Invalid OTP":""}</span>
    
            </Card.Body>
        </Card>
        )
    }
}
export default withRouter(VerifySchool)