import styled,{css} from 'styled-components'
import { Form } from 'react-bootstrap'

export const SearchForm = styled(Form)`
 width: 100%;
  max-width: 500px;
  text-align: center;
  display:block;
  @media(max-width:868px){
      max-width:400px;
      display:none;
  }
`
export const InnerForm = styled.div`
  background: #fff;
  display:flex;
  width: 100%;
  border-radius: 2rem 0 0 2rem;
`
export const InputContainer = styled.div`
height: 45px;
@media(max-width:768px){
    height:45px;
}
${
  (props)=> props.firstWrap ? css`
  -ms-flex-positive: 1;
  flex-grow: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  ` : props.secondWrap ? css`
  width: 60px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  @media(max-width:768px){
      width:80px;
  }
  ` : props.thirdWrap ? css`
  width: 70px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  @media(max-width:768px){
      width:60px;
  }
  `:''
  }
`
export const InputField = styled.input`
  background: transparent;
  height: 100%;
  width: 100%;
  display: block;
  font-size: 16px;
  color: #555;
  border: 0;
  padding: 10px 32px;

`
export const Suggestions = styled.div`
  position: relative;
  display: none;
  max-height: 40vh;
  width: calc(100% - 20px);
  z-index: 1;
  margin-left: auto;
  overflow-y: scroll;
  text-align: left;
  background-color: #fff;
  ${(props)=>props.show? css `
  display:block;
  `:css `display:none;`}
  &::-webkit-scrollbar {
  width: 10px;
}
&::-webkit-scrollbar-track {
  background: #f1f1f1;
}
&::-webkit-scrollbar-thumb {
  background: #888;
}
&::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
`
export const SuggestionList = styled.li`
  padding: 8px 20px;
  list-style: none;
  &:hover{
    background-color: silver;
  cursor: pointer;
  }
`
export const ListPara = styled.p`
  font-size: 12px;
  color: #000;
  display: inline;
`
export const ChoicesOptions  = styled.option`
background-color: #fff;
`
export const ChoicesSelect = styled.select`
   border: 0;
    padding: 4px 0 4px 2px;
    border-radius: 0;
    width: 100%;
    font-size: 14px;
    display: inline-block;
    color: #888;

`
export const InputSelect = styled.div`
  background: white;
  height: 100%;
  color: #fff;
  display: flex;
`