import React, { Component } from 'react'
import axios from 'axios'
import { Form, Button } from 'react-bootstrap';
import { withRouter } from "react-router-dom";

class Filters extends Component {
    state = {
        states:[],
        selectedState:"Select State",
        state_id:null,
        cities:[],
        selectedCity:"",
        city_id:null,
        curValue: 30000,
        selectedRating:null
    }
    componentDidMount(){
        
        axios.post("/getStates")
        .then(res=>{
            console.log(res);
            if(res.status===200 && res.data.status===1){
                this.setState({states:res.data.data})
            }
        })
    }
    handleStateChange = e =>{
        this.setState({selectedState:e.target.value, state_id:e.target.options.selectedIndex},
            this.getCities)
    }
    handleCityChange = e =>{
        e.persist();
        this.setState({selectedCity:e.target.value, city_id:e.target.options.selectedIndex})
    }

    handleChange = e => {
        e.persist();
        this.setState({curValue:e.target.value});
    }

    handleRatingChange = e => {
        e.persist();
        this.setState({selectedRating : parseInt(e.target.value)})
    }
    getCities(){
        let data = new FormData()
        data.set("state_id",this.state.state_id)
        axios.post("/getCities",data)
        .then(res=>{
            if(res.status===200 && res.data.status===1){
                this.setState({cities:res.data.data})
            }
        })
    }

    handleButtonClick = e => {
        console.log(this.state);
        const keyword= this.state.selectedCity?this.state.selectedCity:this.props.query;
        const medium = this.props.medium;
        const classs = this.props.classs;

        this.props.history.push({
            pathname: "/search",
            search:
              "?query=" + keyword +
              "&class=" + classs +
              "&medium=" + medium + 
              "&max_fees=" + this.state.curValue +
              "&rating=" + this.state.selectedRating,
            state: {
                selectedClass: {
                    id:this.props.classId,
                    name:classs
                },
                selectedMedium: {
                    id:this.props.mediumId,
                    name:medium
                },
                maxFees: this.state.curValue,
                rating: this.state.selectedRating
            }
        });

    }
    render() {
        return (
            <div style={{height:"60vh"}}>
            <h3>Filters</h3>
            <p>By Location</p>
            <Form.Group>
            <Form.Control as="select" value={this.state.selectedState} onChange={this.handleStateChange}>
                <option>Select State</option>
                {
                    this.state.states && this.state.states.length>0? this.state.states.map((states,index)=>(
                    <option key={states.state_id}>{states.state_name}</option>

                    )):""
                }
            </Form.Control>
            </Form.Group>
            <Form.Group>
            <Form.Control as="select" value={this.state.selectedCity} onChange={this.handleCityChange}>
                <option>Select City</option>
                {
                    this.state.cities && this.state.cities.length>0?this.state.cities.map((city,index)=>(
                    <option value={city.city_name} key={city.city_id}>{city.city_name}</option>
                    )):""
                }
            </Form.Control>
            </Form.Group>
            <Form.Group>
                <p>Maximum Fees : {this.state.curValue}</p>
                <Form.Control type="range" value={this.state.curValue} min={0} max = {30000} step={500} onChange={this.handleChange}/>
    
            </Form.Group>
            <p>By Rating</p>
            <Form.Control as="select" onChange={this.handleRatingChange}>
            <option>Rating</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            </Form.Control>
            <Form.Group>
                <Button style={{margin:"20px 0 0 0"}}type="submit" variant="secondary" onClick={this.handleButtonClick}>Apply</Button>
            </Form.Group>
            </div>
        )
    }
}

export default withRouter(Filters);