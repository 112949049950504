import React, { Component } from 'react'
import { Form, Jumbotron, Button } from 'react-bootstrap'

export default class GeneralInfo extends Component {
    state = {
    sch_banner:{},
    sch_name:"",
    board_id:0,
    sch_type:null,
    marking_id:null,
    sch_about:"",
    error:false,
    sch_reg:"",
    min_fees:"",
    max_fees:"",
    medium_id:[]
    }
    handleSubmit=e=>{
        e.preventDefault()
        if(this.state.sch_about.trim().length<150){
            alert("description should've atleast 150 words")
            return
        }
        if(this.state.sch_banner && this.state.sch_name.trim()!=="" && this.state.board_id!==0 && this.state.marking_id && this.state.sch_type && this.state.sch_about.trim()!=="" && this.state.min_fees.trim()!=="" && this.state.max_fees.trim()!=="" && this.state.sch_reg.trim()!==""){
            let arr = {
                sch_banner:this.state.sch_banner,
                sch_name:this.state.sch_name,
                sch_type:this.state.sch_type,
                sch_about:this.state.sch_about,
                marking_id:this.state.marking_id,
                board_id:this.state.board_id,
                sch_reg:this.state.sch_reg,
                min_fees:this.state.min_fees,
                max_fees:this.state.max_fees,
                medium_id:this.state.medium_id
            }
            console.log(arr)
            this.props.data.screen(1,arr);
        }else{
            this.setState({error:true})
        }
    }
    handleChange=e=>{
        this.setState({[e.target.name]:e.target.value})
    }
    handleFileChange=e=>{
       if(e.target.files[0] && e.target.files[0].size/(1024*1024)<5){
        this.setState({[e.target.name]:e.target.files[0]})
       }else{
           alert("file should be less than 5Mb")
       }
    }
    handlecheckchange=e=>{
        if(e.target.checked){
            this.setState({
                [e.target.name]:[...this.state.medium_id,e.target.value]
            })
        }else{
            let list = [...this.state.medium_id]
            list.splice(list.indexOf(e.target.value),1)
            this.setState({
                [e.target.name]:list
            })
        }
    }
    render() {
       
        return (
            <React.Fragment>
            <h1 className="text-center">General Information</h1>
            <Form className="p-4" onSubmit={this.handleSubmit}>
            <Form.Group>
                <Jumbotron>
    
                <Form.Control name="sch_banner" onChange={this.handleFileChange} accept="image/png, image/jpeg, image/jpg" type="file" required />
                </Jumbotron>
            </Form.Group>
            <Form.Group>
                <Form.Control placeholder="Registration Number" value={this.state.sch_reg} name="sch_reg" onChange={this.handleChange} type="text" required/>
            </Form.Group>
            <Form.Group>
                <Form.Control value={this.state.sch_name} name="sch_name" onChange={this.handleChange} type="text" placeholder="Name of the School" required/>
            </Form.Group>
            <Form.Group>
                <Form.Control name="board_id" value={this.state.board_id} onChange={this.handleChange} as="select">
                    <option value="0">Select Board</option>
                    <option value="1" key="1">CBSE</option>
                    <option value="2" key="2">RBSE</option>
                    <option value="3" key="3">ICSE</option>
                    <option value="4" key="4">N.C.V.T</option>
                    <option value="5" key="5">Other</option>
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Text>Select Medium</Form.Text>
                <Form.Check onChange={this.handlecheckchange} type="checkbox" name="medium_id" value="1" label="Hindi" />
                <Form.Check onChange={this.handlecheckchange} type="checkbox" name="medium_id" value="2" label="English" />
            </Form.Group>
            <Form.Group>
                <Form.Label>School Type</Form.Label><br/>
                <Form.Check onClick={this.handleChange} name="sch_type" value="1" inline type={"radio"} label="Boys"/>
                <Form.Check onClick={this.handleChange} name="sch_type" value="2" inline type={"radio"} label="Girls" />
                <Form.Check onClick={this.handleChange} name="sch_type" value="3" inline type={"radio"} label="Both"/>
            </Form.Group>
            <Form.Group>
            <Form.Label>Marking System</Form.Label><br/>
                <Form.Check onClick={this.handleChange} name="marking_id" value="1" inline type={"radio"} label="Marks" />
                <Form.Check onClick={this.handleChange} name="marking_id" value="2" inline type={"radio"} label="Grades" />
            </Form.Group>
            <Form.Group>
                <Form.Control placeholder="Min Fees" value={this.state.min_fees} name="min_fees" onChange={this.handleChange} type="number" required/>
            </Form.Group>
            <Form.Group>
                <Form.Control placeholder="Max Fees" value={this.state.max_fees} name="max_fees" onChange={this.handleChange} type="number" required/>
            </Form.Group>
            <Form.Group>
                <Form.Label>About</Form.Label>
                <Form.Control name="sch_about" value={this.state.sch_about} onChange={this.handleChange} as="textarea" required/>
            </Form.Group>
            <Form.Group>
        <span className="text-danger">{this.state.error?"please fill the empty field":""}</span>
            </Form.Group>
            <Form.Group>
            <Button type="submit" variant="secondary">Next</Button>
            </Form.Group>
        </Form>
        </React.Fragment>
        )
    }
}
