import React, { Component } from 'react'
import NavbarDefault from '../Components/Navbar/NavbarDefault'
import Footer from '../Components/Footer'
import { Container } from 'react-bootstrap'
import GeneralInfo from '../Components/FormSteps/GeneralInfo'
import ContactInfo from '../Components/FormSteps/ContactInfo'
import ClassesInfo from '../Components/FormSteps/ClassesInfo'
import Facilities from '../Components/FormSteps/Facilities'
import AdditionInfo from '../Components/FormSteps/AdditionInfo'
import ResultInfo from '../Components/FormSteps/ResultInfo'
import PhotosInfo from '../Components/FormSteps/PhotosInfo'
import AdmissionInfo from '../Components/FormSteps/AdmissionInfo'
import {withRouter} from 'react-router-dom'
import axios from 'axios'
import {instance} from '../utils/instance'
import Success from '../Components/FormSteps/Success'
import MetaTags from 'react-meta-tags'

class registration extends Component {
    state = {
        generalInfo:[],
        contactInfo:[],
        ClasData:[],
        facilityInfo:[],
        excel:{},
        Result:[],
        screen:0,
        photo:[],
        admissionInfo:{},
        user_id:null,
        school_id:null,
    }
    componentDidMount(){
        if(!this.props.location.data){
            this.props.history.push("/registerschool")
        }else{
            this.setState({user_id:this.props.location.data})
        }
        console.log(this.props.location.data)
    }
    mycallback = (screen,data) =>{
        console.log(this.state);
        if(screen===1){
        this.setState({generalInfo:data,screen:screen})
        }else if(screen===2){

            this.setState({contactInfo:data});
            
            let formdata = new FormData();
            formdata.set("sch_banner",this.state.generalInfo.sch_banner)
            formdata.set("board_id",this.state.generalInfo.board_id)
            formdata.set("user_id",this.state.user_id)
            formdata.set("sch_name",this.state.generalInfo.sch_name)
            formdata.set("sch_reg",this.state.generalInfo.sch_reg)
            formdata.set("sch_about",this.state.generalInfo.sch_about)
            formdata.set("state_id",data.state_id)
            formdata.set("city_id", data.city_id)
            formdata.set("latitude", data.latitude)
            formdata.set("longitude", data.longitude)
            formdata.set("pincode", data.pincode)
            formdata.set("contact_email", data.email)
            formdata.set("sch_phone", data.contact_phone)
            formdata.set("sch_address", data.sch_address)
            formdata.set("min_fees",this.state.generalInfo.min_fees)
            formdata.set("max_fees",this.state.generalInfo.max_fees)
            formdata.set('marking_ids',this.state.generalInfo.marking_id)
            formdata.set('school_type_id',this.state.generalInfo.sch_type)
            formdata.set("medium_ids",this.state.generalInfo.medium_id)

            axios.post("/registerSchool",formdata).then(res=>{
                if(res.status===200){
                    console.log(res);
                    console.log("This shoud be id updated");
                    console.log(res.data.school_id);
                    this.setState({
                        school_id:res.data.school_id
                    })
                }
            }).catch(err=>{
                const data = err.response;
                console.log(data)
                // const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                //     JSON.stringify(data)
                //   )}`;
                //   const link = document.createElement("a");
                //   link.href = jsonString;
                //   link.download = "data.json";
              
                //   link.click();
            })
            this.setState({screen:screen})
        }else if(screen===3){
            this.setState({ClasData:data})
            this.setState({screen:screen})
            console.log("For Screen 3, data is ...")
            console.log(data)
            let formdata = new FormData()
            formdata.set("school_id",this.state.school_id)
            
            let array =[]
            array = data.map(function(e){
                return JSON.stringify(e);
            });
            console.log("This is array")
            console.log(array);
            let json =array.join(",")
            console.log("This is json for screen 3")
            console.log(json)
            formdata.set("clasData",json)

            axios.post("/addSchoolClassInfo",formdata)
            .then(res=>{
                if(res.status === 200) {
                    console.log(res);
                }  
            }).catch(err => {
                console.log(err.response);
            })
        }else if(screen===4){
            if(data && data.length>0){
                let formData = new FormData()
                formData.set("school_id",this.state.school_id)
                formData.set("data",JSON.stringify(data))
                console.log(JSON.stringify(data))
                instance.post('/addfees.php',formData)
                .then(res=>{
                    console.log(res)
                    this.setState({
                        screen:screen
                    })
                }).catch(err => {
                    console.log(err.response);
                })
            }else{
                this.setState({
                    screen:screen
                })
            }            
        }else if(screen===5){
            this.setState({screen:screen})
            this.setState({facilityInfo:data})
            let formdata = new FormData()
            formdata.set("school_id",this.state.school_id)
            const facilityStr = data.toString();
            formdata.set("facility_ids",facilityStr)
            axios.post("/addEditFacilitys",formdata)
            .then(res=>{
                console.log(res)
            }).catch(err => {
                console.log(err.response);
            })
            
        }else if(screen===6){
            if(data!=="")
            this.setState({Result:data,screen:screen})
            else
            this.setState({screen:screen})
        }else if(screen===7){
            this.setState({photo:data,screen:screen})
            let formdata = new FormData()
            formdata.set("school_id",this.state.school_id)
            formdata.set("img_name",data)
            for (var pair of formdata.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }
            axios.post("/uploadSchoolImages",formdata,{headers:{ 'Content-Type': 'multipart/form-data'}})
            .then(res=>{
                console.log(res)
            })
        }else if(screen===8){
            // this.setState.admissionInfo =data
            this.setState({admissionInfo:data})
            let formdata = new FormData()
            formdata.set('school_id',this.state.school_id)
            if(data.checked){
                formdata.set("admission_allow",1)
                formdata.set("admission_start_date",data.startDate)
                formdata.set("admission_end_date",data.endDate)
                formdata.set("ad_test_datetime",data.test_date+" "+data.test_time)
                formdata.set("test_on_admission",data.withTest)
                formdata.set("admission_test_fees",data.test_type)
                formdata.set("admission_test_amount",data.amount)
                formdata.set("start_visiting_hrs",data.startTime)
                formdata.set("end_visiting_hrs",data.endTime)
            }else{
                formdata.set("admission_allow",0)
                formdata.set("admission_start_date",data.startDate)
                formdata.set("admission_end_date",data.endDate)
                formdata.set("ad_test_datetime",data.test_date+" "+data.test_time)
                formdata.set("test_on_admission",data.withTest)
                formdata.set("admission_test_fees",data.test_type)
                formdata.set("admission_test_amount",data.amount)
                formdata.set("start_visiting_hrs",data.startTime)
                formdata.set("end_visiting_hrs",data.endTime)
            }
            axios.post("/editSchoolAdmissionInfo",formdata)
            .then(res=>{
                if(res.status===200){
                    this.setState({screen:screen})
                    console.log(res);
                }
           }).catch(err => {
                console.log(err.response);
           })
        }
    }
    render() {
        let content=""
        if(this.state.screen===0){
           content =  <GeneralInfo data={{screen:this.mycallback.bind(this)}} />
        }else if(this.state.screen===1){
            content = <ContactInfo data={{screen:this.mycallback.bind(this)}}/>
        }else if(this.state.screen===2){
            content = <ClassesInfo data={{screen:this.mycallback.bind(this)}} />
        }else if(this.state.screen===3){
            content = <AdditionInfo data = {{screen:this.mycallback.bind(this)}} clasData={this.state.ClasData} />
        }else if(this.state.screen===4){
            content = <Facilities data={{screen:this.mycallback.bind(this)}} />
        }else if(this.state.screen===5){
            content = <ResultInfo data={{screen:this.mycallback.bind(this)}} ff={this.state.school_id} />
        }else if(this.state.screen===6){
            content = <PhotosInfo data={{screen:this.mycallback.bind(this)}} />
        }else if(this.state.screen===7){
            content = <AdmissionInfo data={{screen:this.mycallback.bind(this)}} />
        }else if(this.state.screen===8){
            content = <Success ff={this.state.school_id} />
        }
        return (
            <div>
                <MetaTags>
                    <title>Register School | Feel</title>
                    <meta name="description" content="Login page Feel, Feel Login, Feel Signup, Feel School Search engine, best school near me" />
                    <meta name="keywords" content="Feel Login, Feel educare Login, Feel Signup, schools near me, best schools near me , schools in Rajasthan, Schools in Jaipur" />
                    {/* <meta property="og:image" content="path/to/image.jpg" /> */}
                </MetaTags>
                <NavbarDefault />
                <Container className="col-sm-12 col-md-6 my-4 shadow">
                  {content}
                </Container>
                <Footer/>
            </div>
        )
    }
}
export default withRouter(registration)