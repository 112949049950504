import React, { Component } from 'react'
import { FullContainer, Shade, InnerDiv } from '../Styled/SchoolProfile'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faShareAlt } from '@fortawesome/free-solid-svg-icons'
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { Col, Carousel, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import '../Styled/style.css'
export const Menu = (list) =>
  list.map(el => {
    
    const {name, icon_white} = el;
    return <MenuItem text={name} icon={icon_white} key={name}/>;
  })
  
  const MenuItem = ({text, icon}) => {
    return <Col className="text-center" style={{cursor:"default"}}>
    <img width={30} alt="" src={icon} />
    <p className="text-white">{text}</p>
    </Col>;
  };
const Arrow = ({ text }) => {
    return (
      <div style={{fontSize:"30px",cursor:"pointer"}} className="text-white"
      >{text}</div>
    );
  };

const ArrowLeft = Arrow({ text: '<' });
const ArrowRight = Arrow({ text: '>' });
export default class SchoolHero extends Component {
  
    render() {
      
    let  menu = ""
        if(this.props.data && this.props.data.facilities && this.props.data.facilities.length>0){
      menu = Menu(this.props.data.facilities)
        }
        return (
            <FullContainer>
                <Carousel indicators={false} controls={false} style={{width:"100%"}}>
               
                    {this.props.images.map((image,index)=>(
                         <Carousel.Item key={index}>
                        <img className="res-img" width="100%" style={{objectFit:"cover"}} src={image.url} alt="school-hero-img"/>
                        </Carousel.Item>
                    ))}
                
                </Carousel>
                <div className="res-abs">
                <div style={{display:"flex", flexDirection:"column",height:"100%"}}>
                    <Shade className="text-center" top>
                    <Button href={localStorage.getItem('isLogged')==='true' && localStorage.getItem('type')==='school'?'/admissionstatus':''} style={localStorage.getItem('isLogged')==='true' && localStorage.getItem('type')==='school'?{borderRadius:"20px",border:"none",fontSize:"12px"}:{display:"none"}} className="float-left mt-2 ml-2" variant="light">Admissions: {this.props.count}</Button>
                    <h1 style={{display:"inline-block"}} className="text-white mt-2 resph1">{this.props.data?this.props.data.sch_name:""}</h1>
                    <a href="whatsapp://send?text=https://www.feeleducare.com" data-action="share/whatsapp/share"><FontAwesomeIcon  color="#fff" size="2x" className="float-right mt-2 mr-2" icon={faShareAlt} /></a>
                    </Shade>
                    <InnerDiv></InnerDiv>
                    <Shade style={{display:"flex",alignItems:"center"}} bottom>
                    <ScrollMenu alignCenter menuStyle={{margin:"auto",width:"80%"}} data={menu} arrowLeft={ArrowLeft} arrowRight={ArrowRight} />
                    </Shade>
                    </div>
                </div>
                </FullContainer>

        )
    }
}
