import React, { Component } from 'react'
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSync } from '@fortawesome/free-solid-svg-icons'

class AdmissionStatus extends Component {
    handleCancel= e=>{
        let data = new FormData()
        data.set("add_id",e)
        data.set("status","Cancelled")
        axios.post("/admissionStatusUpdate",data)
        .then(res=>{
            console.log(res);
            if(res.status===200){
                if(res.data.status===1){
                    window.location = "/admissionstatus"
                  
                }
            }
        })

    }
    handleAction = e=>{
        let data = new FormData()
        data.set("add_id",e)
        if(this.props.tab===2){
            data.set("status","Completed")
        }else{
            data.set("status","Ongoing")
        }
        axios.post("/admissionStatusUpdate",data)
        .then(res=>{
            if(res.status===200){
                if(res.data.status===1){
                      window.location = "/admissionstatus"
                }
            }
        })
    }
    render() {
        return (
            <React.Fragment> 
                {   
                this.props.data.length>0? this.props.data.map((ff,index)=>(
                    <div key={index} className="card">
                        <div className="card-body">
                            <h5>{ff.student_name}</h5>   
                            <p>Visit Time: {ff.datetime}</p>
                            <div className="row">
                                <div className="col">
                                    <p>class: {ff.class}</p>
                                </div>
                                <div className="col">
                                    <p>Medium: {ff.medium}</p>
                                </div>
                                <div className="col">
                                    <p>Board: {ff.board}</p>
                                </div>
                            </div>
                            <p>Admission ID: {ff.admission_id}</p>
                            {this.props.tab===3?<p>Status: completed</p>:''}
                            {this.props.tab===2?<p>Fees: {ff.fees}</p>:''}
                            <div className="float-right">
                                <button onClick={this.handleCancel.bind(this,ff.id)}  style={this.props.tab===3|| this.props.tab===4?{display:"none"}:{}} className="btn btn-primary m-2">Cancel</button>
                                <button onClick={this.handleAction.bind(this,ff.id)} style={this.props.tab===3|| this.props.tab===4?{display:"none"}:{}} className="btn btn-primary">{this.props.tab===1? "Visit Complete":this.props.tab===2?"Collect Fees":""}</button>
                            </div>
                        </div>
                    </div>    
                )): this.props.isLoading?<h4 style={{textAlign: "center"}}className="mx-auto">Synchronizing &nbsp;<FontAwesomeIcon  icon={faSync} spin /></h4>:<h4 style={{textAlign:"center", margin:"20px"}}>No record found</h4>
                }
            </React.Fragment>   
        )
    }
}
export default withRouter(AdmissionStatus)