import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import axios from 'axios'

export default class Facilities extends Component {
    state = {
        facilities:[],
        selectedFacilities:[],
        error:false
    }
    componentDidMount(){
        axios.get("/getCommonData")
        .then(res=>{
            if(res.status===200){
                if(res.data.data.status===1){
                    this.setState({facilities:res.data.data.facilities})
                }
            }
        })
    }
    handleSubmit=e=>{
        e.preventDefault()
        if(this.state.selectedFacilities.length>0)
        this.props.data.screen(5,this.state.selectedFacilities)
        else
        this.setState({error:true})
    }
    handleChange =e =>{
        if(e.target.checked){
            
        this.setState({[e.target.name]:[...this.state.selectedFacilities,e.target.value],error:false})
        }
        else{
            let list = [...this.state.selectedFacilities]
            list.splice(list.indexOf(e.target.value),1)
        this.setState({[e.target.name]:list})
        }
    }
    render() {
        console.log(this.state.selectedFacilities)
        return (
            <div>
               <h1 className="text-center">Choose Facilities</h1> 
               <Form className="p-2" onSubmit={this.handleSubmit}>
                   <Form.Group>
                       {
                           this.state.facilities && this.state.facilities.length>0?this.state.facilities.map((facility,index)=>(
                            <Form.Check name="selectedFacilities" value={facility.id} onChange={this.handleChange} key={index} type="checkbox" label={facility.name} />
                           )):""
                       }
                       
                   </Form.Group>
                   <Form.Group>
                    <span className="text-danger">{this.state.error?"Please select facilities":""}</span>
                   </Form.Group>

                   <Form.Group>
                       <Button type="submit" variant="secondary">Next</Button>
                   </Form.Group>
               </Form>
            </div>

        )
    }
}
