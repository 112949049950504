import styled, {css} from 'styled-components'
import { Container, Button } from 'react-bootstrap';

export const Maindiv = styled.div`
height:100vh;
width:100%;
`
export const Logo = styled.img`
width:150px;
height: 150px;
@media(max-width: 768px){
width:100px;
height: 100px;
}
`
export const FormSearch = styled.form`
  width: 100%;
  max-width: 790px;
  text-align: center;
  @media(max-width:768px){
      max-width:600px;
  }
`

export const InnerForm = styled.div`
  background: #fff;
  display: -ms-flexbox;
  display: flex;
  width: 100%;   
  -ms-flex-align: center;
      align-items: center;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 2rem 0 0 2rem;
`
export const InputContainer = styled.div`
height: 68px;
@media(max-width:768px){
    height:50px;
}
${
  (props)=> props.firstWrap ? css`
  -ms-flex-positive: 1;
  flex-grow: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  ` : props.secondWrap ? css`
  width: 130px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  @media(max-width:768px){
      width:80px;
  }
  ` : props.thirdWrap ? css`
  width: 130px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  @media(max-width:768px){
      width:60px;
  }
  `:''
  }
`
export const InputField = styled.input`
  background: transparent;
  height: 100%;
  width: 100%;
  display: block;
  font-size: 16px;
  color: #555;
  border: 0;
  padding: 10px 32px;

`
export const Suggestions = styled.div`
  position: relative;
  display: none;
  max-height: 40vh;
  width: calc(100% - 20px);
  z-index: 1;
  margin-left: auto;
  overflow-y: scroll;
  text-align: left;
  background-color: #fff;
  ${(props)=>props.show? css `
  display:block;
  `:css `display:none;`}
  &::-webkit-scrollbar {
  width: 10px;
}
&::-webkit-scrollbar-track {
  background: #f1f1f1;
}
&::-webkit-scrollbar-thumb {
  background: #888;
}
&::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
`
export const InputSelect = styled.div`
  background: white;
  height: 100%;
  color: #fff;
  display: flex;
`
export const ChoicesSelect = styled.select`
   border: 0;
    padding: 4px 0 4px 2px;
    border-radius: 0;
    width: 100%;
    font-size: 14px;
    display: inline-block;
    color: #888;

`
export const ChoicesOptions  = styled.option`
background-color: #fff;
`
export const FullContainer = styled(Container)`
padding:15px;
height:90%;
display:flex;
justify-content:space-evenly;
flex-direction:column;
align-items:center;
@media(max-width:768px){
    padding:0;
   height:70%; 
}
`
export const StartButton = styled(Button)`
width:10rem;
`

export const Slogan = styled.h3`
@media(max-width:768px){
    font-size:18px;
}
`
export const SuggestionList = styled.li`
  padding: 10px 30px;
  list-style: none;
  &:hover{
    background-color: silver;
  cursor: pointer;
  }
`
export const ListPara = styled.p`
  font-size: 14px;
  color: #000;
  display: inline;
`