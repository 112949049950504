import styled, { css } from "styled-components";
import { Button } from "react-bootstrap";

export const MainDiv = styled.div`
  width: 100%;
  height: 92vh;
  @media (max-width: 768px) {
    height: 65vh;
  }
`;
export const StyledButton = styled(Button)`
  border-radius: 20px;
`;

export const FullContainer = styled.div`
  width: 100%;
  position: relative;
`;
export const Shade = styled.div`
  height: 20vh;
  width: 100%;
  ${(props) =>
    props.bottom
      ? css`
          bottom: 0;
          background: linear-gradient(#f9f9f900, #000000);
        `
      : ``}
  ${(props) =>
    props.top
      ? css`
          top: 0;
          background: linear-gradient(#000000, #f9f9f900);
        `
      : ``}
`;
export const InnerDiv = styled.div`
  flex-grow: 1;
`;
