import React, { Component } from 'react'
import { Form, Button, FormControl, Row, Col } from 'react-bootstrap'
import axios from 'axios'

export default class AdditionInfo extends Component {
    state = {
        error:false,
        classes:[],
        fees:[]
    }
    temp = []
    componentDidMount(){
        axios.get("/getCommonData")
        .then(res=>{
            console.log(res)
            if(res.status===200 && res.data.data.status===1){
                this.setState({
                    classes:res.data.data.classes
                })
            }
        })
    }

    handleSubmit= e =>{
        e.preventDefault()
        if(this.state.fees.length===this.props.clasData[0].length){
            this.props.data.screen(4,this.state.fees)
        }else{
            this.setState({error:true})
        }
    }

    handleChange=(id,name,index,e)=>{
        this.temp[index]={class_id:id,class_name:name,fees:e.target.value}
        this.setState({
            [e.target.name]:this.temp,
            error:false
        })
    }
    handleSkip=e=>{
        this.props.data.screen(4,null)
    }
    render() {
        // console.log(this.state.error)
        return (
            <div>
                <h1 className="text-center">Add Fees</h1>
                <Form onSubmit={this.handleSubmit} className="p-2">
                    <Form.Group>
                        {
                            this.state.classes.length>0?this.state.classes.map((cl,index)=>(
                                this.props.clasData[0]?this.props.clasData[0].map((cldata,index)=>(
                                    parseInt(cldata.class_id)===cl.id?
                                <Row className="mb-2" key={index}>
                                    <Col>
                                    <label>Class: {cl.name}</label>
                                    </Col>
                                    <Col>
                                    <FormControl onChange={this.handleChange.bind(this,cldata.class_id,cl.name,index)} name="fees" type="number" placeholder="Annual Fees"/>
                                    </Col>
                                </Row>:""
                                ))
                                :""
                            )):""
                        }
                        
                    </Form.Group>
                    <Form.Group>
                        <span className="text-danger">{this.state.error?"Please enter fees":""}</span>
                    </Form.Group>
                    <Form.Group>
                        <Button onClick={this.handleSkip}  variant="secondary mr-2">Skip</Button>
                        <Button onClick={this.handleSubmit} type="submit" variant="secondary">Next</Button>
                    </Form.Group>
                </Form>
            </div>
        )
    }
}
