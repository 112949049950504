import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import {FullContainer,WelcomeLabel, Image,Maindiv} from '../Styled/Section'
import kid from '../assets/images/kid.webp'

export default class SectionFirst extends Component {
    render() {
        return (
            <div style={{marginBottom : "13vh"}}>
                <FullContainer fluid className="" style={{display:"block"}}>
                    <Row style={{width:"100%"}} className="text-center my-auto">
                        <Col sm={12} md={6}>
                            <ScrollAnimation duration={3} animateOut="animate__animated animate__fadeOut" animateIn="animate__animated animate__fadeIn"><WelcomeLabel className="text-dark">Welcome</WelcomeLabel></ScrollAnimation>
                            <h2>To the best school search engine in India.</h2>
                            <p>Feel is the Largest school search engine in India. School search engines are a great way to find the best schools for your child. They can give you an idea of the prices, location and other important information about the schools.</p>
                            { /* <p>Feel is the Largest school search engine in India. School search engines are a great way to find the best schools for your child.</p> */}
                            <p>Feel is an online school search engine that helps you find the right school for you. We list all the top schools in the country. Our goal is to help you find the right school for your needs by providing you with information on tuition costs, student reviews, fascilities and more.</p>
                        </Col>
                        <Col sm={12} md={6}>
                            <Image style={{display:"inline-block"}} src={kid} width={500} widthSm={300} widthMd={350} height={374} heightSm={225} heightMd={262} alt="Feel School Search Engine in India" />
                        </Col>
                    </Row>
                </FullContainer>
            </div>
        )
    }
}
